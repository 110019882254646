
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  deleteTenantUserAPI,
  getOrgUserDataAPI,
  updateTenantUserAPI,
  addTenantUserAPI,
  updateTenantUserStatusAPI,
  getUserAssetsDataAPI,
  deleteUserAssetAPI
} from '../apis/UserManagementAPI';
import { AssetUser } from '../models';
import { RootState } from 'src/store/reducer';

interface UserManagementState {
  orgUserData: AssetUser[];
  loadingUserData: boolean;
  isOrgUserListUpdated: boolean;
  isError: boolean;
  errorMessage: string;
  userAssetsData: any;
  isUserAssetsListUpdated: boolean;
}

const initialState: UserManagementState = {
  orgUserData: [],
  loadingUserData: false,
  isOrgUserListUpdated: false,
  isError: false,
  errorMessage: '',
  userAssetsData: [],
  isUserAssetsListUpdated: false
};
export const selectorUserManagementState = ({
  userManagementData
}: RootState) => ({
  orgUserData: userManagementData?.orgUserData,
  loadingUserData: userManagementData?.loadingUserData,
  isOrgUserListUpdated: userManagementData?.isOrgUserListUpdated,
  isError: userManagementData?.isError,
  errorMessage: userManagementData?.errorMessage,
  userAssetsData: userManagementData?.userAssetsData,
  isUserAssetsListUpdated: userManagementData?.isUserAssetsListUpdated,
});
export const getOrganizationUserData = createAsyncThunk(
  'UserManagementData/getOrganizationUserData',
  async (orgId: string) => {
    const response = await getOrgUserDataAPI(orgId);
    return response;
  }
);

export const deleteTenantUser = createAsyncThunk(
  'UserManagementData/deleteTenantUser',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await deleteTenantUserAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addTenantUser = createAsyncThunk(
  'UserManagementData/addTenantUser',
  async (UserPayload: any, { rejectWithValue }) => {
    try {
      const response = await addTenantUserAPI(UserPayload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateTenantUser = createAsyncThunk(
  'UserManagementData/updateTenantUser',
  async (userData: object, { rejectWithValue }) => {
    try {
      const response = await updateTenantUserAPI(userData);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateTenantUserStatus = createAsyncThunk(
  'UserManagementData/updateAdminUserStatus',
  async (userData: object, { rejectWithValue }) => {
    try {
      const response = await updateTenantUserStatusAPI(userData);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getUserInsert = (userList) => {
  return [
    ...userList.map((entry) => {
      return {
        id: entry.email,
        email: entry.email,
        name: entry.name,
        phoneNumber: entry.phoneNumber,
        isEnabled: entry.isEnabled,
        role: {
          id: entry.roles.id,
          roleName: entry.roles.role_name
        },
        tenantId: entry.tenantId
      };
    })
  ];
};

const getAssetUsersDTO = (masterList) => {
  return [
    ...masterList.map((entry) => {
      if (entry.id) {
        return entry;
      }
      return {
        id: entry.user_id,
        name: entry.asset_name,
        description: entry.asset_description,
        assetId: entry.asset_id,
        userId: entry.user_id
      };
    })
  ];
};

export const getUserAssetsData = createAsyncThunk(
  'UserManagementData/getUserAssetsData',
  async (payload: any) => {
    const response = await getUserAssetsDataAPI(payload);
    return response;
  }
);

export const deleteAssetOfUser = createAsyncThunk(
  'UserManagementData/deleteTenantUser',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await deleteTenantUserAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteUserAssets = createAsyncThunk(
  'UserManagementData/deleteUserAssets',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await deleteUserAssetAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userManagementSlice = createSlice({
  name: 'userManagementData',
  initialState,
  reducers: {
    setIsAdminListUpdated: (state: any, action) => {
      state.isOrgUserListUpdated = action.payload;
    },
    setIsError: (state: any, action) => {
      state.isError = action.payload;
      if (!action.payload) {
        state.errorMessage = '';
      }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getOrganizationUserData.pending, (state) => {
        state.loadingUserData = true;
        state.orgUserData = [];
      })
      .addCase(getOrganizationUserData.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        if (action.payload) {
          const orgUserData = getUserInsert(action.payload);
          state.orgUserData = orgUserData;
        }
      })
      .addCase(getOrganizationUserData.rejected, (state) => {
        state.loadingUserData = false;
      })
      .addCase(addTenantUser.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(addTenantUser.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.isOrgUserListUpdated = true;
      })
      .addCase(addTenantUser.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(updateTenantUser.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(updateTenantUser.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.isOrgUserListUpdated = true;
      })
      .addCase(updateTenantUser.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(deleteTenantUser.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(deleteTenantUser.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.isOrgUserListUpdated = true;
      })
      .addCase(deleteTenantUser.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(updateTenantUserStatus.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(updateTenantUserStatus.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.isOrgUserListUpdated = true;
      })
      .addCase(updateTenantUserStatus.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(getUserAssetsData.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        if (action.payload) {
          const userAssetsData = getAssetUsersDTO(action.payload);
          state.userAssetsData = userAssetsData;
          state.isUserAssetsListUpdated = false;
        }
      })
      .addCase(deleteUserAssets.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.isUserAssetsListUpdated = true;
      });
  }
});

export const { setIsAdminListUpdated, setIsError } =
  userManagementSlice.actions;
export default userManagementSlice.reducer;
