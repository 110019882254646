export const LOGIN = '/';
export const SET_PASSWORD = '/set-password';
export const FORGET_PASSWORD = '/forget-password';
export const ADMIN_FORGET_PASSWORD = '/admin/forget-password';
export const CHECK_YOUR_EMAIL = '/check-your-email';
export const ERROR_PAGE = '/not-found';

export const ADMIN_LOGIN = '/admin';
export const TENANT_LOGIN = '/tenant';

export const DASHBOARD = '/dashboards';
export const ORGANIZATION = '/organization';
export const ADMIN_MANAGEMENT = '/admin-management';

export const USER_MANAGEMENT = '/user-management';

export const BOOKING_MANAGEMENT = '/booking-management';
export const BOOKING_VIEW = '/booking-view';

export const INVOICE_MANAGEMENT = '/invoice-management';

 export const STORE_MANAGEMENT = '/store-management';

 export const EXPENCE_MANAGEMENT = '/expence-management';

export const ASSET_MANAGEMENT = '/asset-management';
export const SOLD_ASSET_MANAGEMENT = '/asset-nsl';
  export const ASSET_DETAILS = '/asset-details';

export const ASSET_GROUP_MANAGEMENT = '/asset-group-management';
   
export const ASSET_TYPE_MANAGEMENT = '/asset-type-management';

export const COLOR_MANAGEMENT = '/color-management';

export const FIRM_MANAGEMENT = '/firm-management';

export const LOCATION_MANAGEMENT = '/location_management';

export const PATTERN_MANAGEMENT = '/pattern_management';

export const PERMISSION_MASTER_MANAGEMENT = '/permission-master_management';

export const PERMISSION_MANAGEMENT = '/permission_management';

export const REPORT_MANAGEMENT = '/report';


export const ROLE_MANAGEMENT = '/role_management';

export const SIZE_MANAGEMENT = '/size_management';

export const STOCK_TYPE_MANAGEMENT = '/stock-type_management';