import { createContext, useContext, useState } from 'react';
import { AuthProviderType } from './models/AuthguardContextType';
import {
  getLogOutUser,
  selectUserState
} from 'src/auth/login/services/userSlice';
import { useDispatch, useSelector } from 'react-redux';

export const useAuthProvider = () => {
  const dispatch = useDispatch<any>();
  const { userInfo } = useSelector(selectUserState);
  // State Values
  const [accessToken, setAccessToken] = useState(userInfo?.token);
  const [roles, setUserRoles] = useState(
    JSON.parse(localStorage.getItem('userRoles')) || ([] as string[])
  ); // TODO:: Update user roles on change of redux value

  // Methods
  const login = (authToken: string) => {
    setAccessToken(authToken);
  };
  const logout = async () => {
    await dispatch(getLogOutUser(null));
  };
  const setRoles = (userRoleList: string[]) => {
    setUserRoles(userRoleList);
  };

  return {
    accessToken,
    roles,
    login,
    logout,
    setRoles
  };
};
const defaultAuthContext = {};

const AuthContext = createContext<AuthProviderType>(defaultAuthContext as any);

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const auth = useAuthProvider();
  return (
    <AuthContext.Provider value={auth as any}>{children}</AuthContext.Provider>
  );
};
