import {
  Config,

  apiGet,
  apiPatch,
  apiPost,
  apiPut
} from 'src/shared/utils/api-request';

export const getStoreByTenantIdDataAPI = async (tenantId: string) => {
  const config = {} as Config;
  config.custom = {
    setLoading: () => { }
  };

  const res = await apiGet(`/tenant/${tenantId}/store`);
  return res.data;
};



export const addStoreAPI = async (payload: any) => {
  const res = await apiPost(`/v1/store`, {
    status: payload.status,
    name: payload.name,
    billNo: payload.bill_no,
    code: payload.code,
    tenantId: payload.tenantId,
  });
  return res.data;
};

export const updateTenantUserAPI = async (payload: any) => {
  const res = await apiPut(
    `/v1/store/${payload.id}`,
    {
      status: payload.status,
      name: payload.name,
      billNo: payload.bill_no,
      code: payload.code,
      tenantId: payload.tenantId,
    }
  );
  return res.data;
};

export const updateTenantUserStatusAPI = async (userData: any) => {
  const res = await apiPatch(
    `/tenant/${userData.tenantId}/user/${userData.email}/enable`,
    { value: userData.value }
  );
  return res.data;
};

export const getUserAssetsDataAPI = async (payload) => {
  const config = {} as Config;
  config.custom = {
    setLoading: () => { }
  };
  const res = await apiGet(
    `/organization/${payload.orgId}/user/${payload.user}/assets`
  );
  return res.data;
};


