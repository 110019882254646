import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Box, Button, Hidden, Popover } from '@mui/material';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { useAuth } from 'src/providers/AuthguardContext';
import { Typography } from 'src/shared/components/index';
import { useNavigate } from 'react-router';
import * as ROUTES from 'src/shared/constants/routes';
import { RootState } from 'src/store/reducer';
import { ROLES, getRole } from 'src/shared/constants/constants';
import { selectUserState } from 'src/auth/login/services/userSlice';

const HeaderUserbox = () => {
  const { userInfo, loginUserRole } = useSelector(selectUserState);

  const ref = useRef<HTMLButtonElement>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const userLogout = async () => {
    await logout();
    navigate(
      loginUserRole === ROLES.ADMIN_OPERATOR ||
        loginUserRole === ROLES.SUPER_ADMIN
        ? ROUTES.ADMIN_LOGIN
        : ROUTES.LOGIN
    );
  };

  return (
    <>
      <Button color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar
          variant="rounded"
          alt={userInfo?.name}
          src={userInfo?.image_url}
        />
        <Hidden mdDown>
          <Box className="UserBoxText">
            <Typography className="UserBoxLabel" variant="body1">
              {userInfo?.name}
            </Typography>
            <Typography className="UserBoxDescription" variant="body2">
              {getRole(loginUserRole)}
            </Typography>
          </Box>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </Button>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box className="m-width-Userbox MenuUserBox">
          <Avatar
            variant="rounded"
            alt={userInfo?.name}
            src={userInfo?.image_url}
          />
          <Box className="UserBoxText">
            <Typography className="UserBoxLabel" variant="body1">
              {userInfo?.name}
            </Typography>
            <Typography className="UserBoxDescription" variant="body2">
              {getRole(loginUserRole)}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={userLogout}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default HeaderUserbox;
