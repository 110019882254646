import { Config, apiGet, apiPost, apiPut } from 'src/shared/utils/api-request';

export const getOrganizationDataAPI = async () => {
  const config = {} as Config;
  config.custom = {
    setLoading: () => {}
  };
  const res = await apiGet('/tenant');
  return res.data;
};

export const getTenantUserOrganizationDataAPI = async (userId: string) => {
  const res = await apiGet(`/user/${userId}/company`);
  return res.data;
};

// Add New Organization.
export const addOrganizationAPI = async (org: any) => {
  const res = await apiPost('/tenant', org);
  return res.data;
};

export const updateOrganizationAPI = async (organization: any) => {
  const res = await apiPut('/tenant/' + organization.id, 
    organization,
  );
  return res.data;
};

export const updateOrganizationPermissionAPI = async (payload: any) => {
  const res = await apiPut(
    '/access/tenant/' + payload.orgId + '/role/' + payload.role,
    {
      value: payload.flag
    }
  );
  return res.data;
};
export const getTenantPermissionDetailsAPI = async (payload: any) => {
  const res = await apiGet(
    `access/tenant/${payload.tenantId}/role/${payload.role}`
  );
  return res.data;
};
