import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { I18N } from '../../../shared/constants/constants';
import { useEffect, useState } from 'react';
import { getCurrentDiviceType, DeviceType } from '../../../shared/utils/utils';

const useStyles = makeStyles({
  datatable: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      outline: 'none !important'
    }
  }
});

const useCellStyles = makeStyles({
  root: {
      '& .MuiDataGrid-cell': {
          overflow: 'visible',
          whiteSpace: 'normal !important',
          wordWrap: 'break-word',
      },
  },
});

export const Tables = (props) => {
  const { rows, columns, checkboxSelection = true } = props;
  const { getRowClassName } = props;
  const { t } = useTranslation(I18N.DEFAULT);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const [tableCol, setTableCol] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    const currentDevice: DeviceType = getCurrentDiviceType();
    if (currentDevice === DeviceType.MOBILE) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    handleResize();
  }, []);

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })
  
  useEffect(() => {
    if (isMobile) {
      if (columns.length > 0) {
        const newColList = [];
        for (let i = 1; i < columns.length; i++) {
          const col = { ...columns[i] };
          if (col.flex) {
            delete (col.flex);
          }
          newColList.push(col);
        }
        setTableCol(newColList);
      }
    } else {
      setTableCol(columns);
    }
  }, [isMobile, columns]);

  const classes = useStyles();
  const cellCss = useCellStyles();
  return (
    <div className={`${cellCss.root} ${classes.datatable} tablestyle`}>
      <DataGrid
        rows={rows}
        getRowClassName={getRowClassName}
        getRowHeight={() => isMobile ? 'auto' : null}
        columns={tableCol}
        checkboxSelection={checkboxSelection}
        disableColumnFilter={false}
        pageSizeOptions={[10, 20, 50, 100]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        disableColumnMenu
        className={'table'}
        autoHeight={true}
        disableRowSelectionOnClick={true}
        disableDensitySelector={true}
        classes={{ root: classes.datatable }}
        localeText={{ noRowsLabel: t('table.noResultFound') }}
      />
    </div>
  );
};