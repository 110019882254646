import {
  Config,
  apiDelete,
  apiGet,
  apiPatch,
  apiPost,
  apiPut
} from 'src/shared/utils/api-request';

export const getTenantBookingDataAPI = async (payload: any) => {
  let url: string = `/v1/booking/tenant/${payload.tenantId}`;
  if (payload.isFuture) {
    url = `${url}?isFuture=true`;
  } else if (payload.isPast) {
    url = `${url}?isPast=true`;
  }
  if(payload.isBookingAsset) {
    url = `${url}${url.includes('?')?'&':'?'}isBookingAsset=true`;
  }
  if(payload.startDate) {
    url = `${url}${url.includes('?')?'&':'?'}startDate=${payload?.startDate?.valueOf()}`;
  }
  if(payload.endDate) {
    url = `${url}${url.includes('?')?'&':'?'}endDate=${payload?.endDate?.valueOf()}`;
  }
  const res = await apiGet(url);
  return res.data;
};

export const addBookingAPI = async (payload: any) => {
  const res = await apiPost(`/v1/booking`, payload);
  return res.data;
};

export const findBookingByIdAPI = async (bookingId: any) => {
  const res = await apiGet(`/v1/booking/${bookingId}`);
  return res.data;
};

export const updateBookingAssetAPI = async (payload: any) => {
  const res = await apiPut(
    `/v1/booking/${payload.bookingId}/update-asset-status`,
    payload
  );
  return res.data;
};

export const collectRentAPI = async (payload: any) => {
  const res = await apiPost(
    `/v1/booking/${payload.bookingId}/rent`,
    payload
  );
  return res.data;
};

export const cancelBookingAPI = async (payload: any) => {
  const res = await apiPost(
    `/v1/booking/${payload.bookingId}/cancel`,
    payload
  );
  return res.data;
};

export const depositeUpdateAPI = async (payload: any) => {
  const res = await apiPut(
    `/v1/booking/${payload.bookingId}/deposite-status/${payload.status}`, payload
  );
  return res.data;
};


// export const updateTenantUserStatusAPI = async (userData: any) => {
//   const res = await apiPatch(
//     `/tenant/${userData.tenantId}/user/${userData.email}/enable`,
//     { value: userData.value }
//   );
//   return res.data;
// };

// export const getUserAssetsDataAPI = async (payload) => {
//   const config = {} as Config;
//   config.custom = {
//     setLoading: () => {}
//   };
//   const res = await apiGet(
//     `/organization/${payload.orgId}/user/${payload.user}/assets`
//   );
//   return res.data;
// };

// export const deleteUserAssetAPI = async (payload: any) => {
//   const res = await apiDelete(
//     `organization/${payload.tenantId}/assets/${payload.assetId}/user/${payload.userId}`
//   );
//   return res.data;
// };
