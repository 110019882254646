import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  
  getStoreByTenantIdDataAPI,
  updateTenantUserAPI,
  addStoreAPI,
  updateTenantUserStatusAPI,
  getUserAssetsDataAPI,
 
} from '../apis/storeManagementAPI';
import { AssetUser } from '../models';
import { RootState } from 'src/store/reducer';

interface StoreManagementState {
  tenantStoreData: any[];
  loadingUserData: boolean;
  isOrgUserListUpdated: boolean;
  isError: boolean;
  errorMessage: string;
  isUserAssetsListUpdated: boolean;
}

const initialState: StoreManagementState = {
  tenantStoreData: [],

  loadingUserData: false,
  isOrgUserListUpdated: false,
  isError: false,
  errorMessage: '',
  isUserAssetsListUpdated: false
};
export const selectorStoreManagementState = ({
  store
}: RootState) => ({
  tenantStoreData: store?.tenantStoreData,
  loadingUserData: store?.loadingUserData,
  isOrgUserListUpdated: store?.isOrgUserListUpdated,
  isError: store?.isError,
  errorMessage: store?.errorMessage,
  userAssetsData: store?.userAssetsData,
  isUserAssetsListUpdated: store?.isUserAssetsListUpdated,
});
export const getStoreByTenantIdData = createAsyncThunk(
  'StoreManagementData/getStoreByTenantIdData',
  async (orgId: string) => {
    const response = await getStoreByTenantIdDataAPI(orgId);
  
    return response;
  }
);

 

export const addStore = createAsyncThunk(
  'StoreManagementData/addStore',
  async (UserPayload: any, { rejectWithValue }) => {
    try {
      const response = await addStoreAPI(UserPayload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateTenantUser = createAsyncThunk(
  'StoreManagementData/updateTenantUser',
  async (userData: object, { rejectWithValue }) => {
    try {
      const response = await updateTenantUserAPI(userData);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateTenantUserStatus = createAsyncThunk(
  'StoreManagementData/updateAdminUserStatus',
  async (userData: object, { rejectWithValue }) => {
    try {
      const response = await updateTenantUserStatusAPI(userData);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserAssetsData = createAsyncThunk(
  'StoreManagementData/getUserAssetsData',
  async (payload: any) => {
    const response = await getUserAssetsDataAPI(payload);
    return response;
  }
);

 

export const storeSlice = createSlice({
  name: 'storeManagementData',
  initialState,
  reducers: {
    setIsAdminListUpdated: (state: any, action) => {
      state.isOrgUserListUpdated = action.payload;
    },
    setIsError: (state: any, action) => {
      state.isError = action.payload;
      if (!action.payload) {
        state.errorMessage = '';
      }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getStoreByTenantIdData.pending, (state) => {
        state.loadingUserData = true;
        state.tenantStoreData = [];
      })
      .addCase(getStoreByTenantIdData.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
      
        if (action.payload) {
          const tenantStoreData = action.payload;
          state.tenantStoreData = tenantStoreData;
           
        }
      })
      .addCase(getStoreByTenantIdData.rejected, (state) => {

        state.tenantStoreData = [];
        state.loadingUserData = false;
      })
      .addCase(addStore.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(addStore.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.isOrgUserListUpdated = true;
      })
      .addCase(addStore.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(updateTenantUser.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(updateTenantUser.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.isOrgUserListUpdated = true;
      })
      .addCase(updateTenantUser.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      
      .addCase(updateTenantUserStatus.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(updateTenantUserStatus.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.isOrgUserListUpdated = true;
      })
      .addCase(updateTenantUserStatus.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(getUserAssetsData.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        if (action.payload) {
          const userAssetsData = action.payload
          state.userAssetsData = userAssetsData;
          state.isUserAssetsListUpdated = false;
        }
      })
     
  }
});

export const { setIsAdminListUpdated, setIsError } =
  storeSlice.actions;
export default storeSlice.reducer;
