import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  deleteTenantUserAPI,
  getPatternByTenantIdDataAPI,
  updateTenantUserAPI,
  addTenantUserAPI,
  updateTenantUserStatusAPI,
  getUserAssetsDataAPI,
  deleteUserAssetAPI
} from '../apis/patternManagementAPI';
import { AssetUser } from '../models';
import { RootState } from 'src/store/reducer';
import { Pattern } from '@mui/icons-material';

interface UserManagementState {
  tenantPatternData: any[];

  loadingUserData: boolean;
  isOrgUserListUpdated: boolean;
  isError: boolean;
  errorMessage: string;
  userAssetsData: any;
  isUserAssetsListUpdated: boolean;
}

const initialState: UserManagementState = {
  tenantPatternData: [],
  loadingUserData: false,
  isOrgUserListUpdated: false,
  isError: false,
  errorMessage: '',
  userAssetsData: [],
  isUserAssetsListUpdated: false
};
export const selector_Pattern_Management_State = ({
  pattern
}: RootState) => ({
  tenantPatternData:pattern?.tenantPatternData,
   loadingUserData: pattern?.loadingUserData,
   isOrgUserListUpdated: pattern?.isOrgUserListUpdated,
   isError:pattern?.isError,
   errorMessage:pattern?.errorMessage,
   userAssetsData: pattern?.userAssetsData,
   isUserAssetsListUpdated:pattern?.isUserAssetsListUpdated,
});
export const getPatternByTenantIdData = createAsyncThunk(
  'PatternManagementData/getPatternByTenantIdData',
  async (orgId: string) => {
    const response = await getPatternByTenantIdDataAPI(orgId);
    return response;
  }
);

export const deleteTenantUser = createAsyncThunk(
  'PatternManagementData/deleteTenantUser',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await deleteTenantUserAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addTenantUser = createAsyncThunk(
  'PatternManagementData/addTenantUser',
  async (UserPayload: any, { rejectWithValue }) => {
    try {
      const response = await addTenantUserAPI(UserPayload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateTenantUser = createAsyncThunk(
  'PatternManagementData/updateTenantUser',
  async (userData: object, { rejectWithValue }) => {
    try {
      const response = await updateTenantUserAPI(userData);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateTenantUserStatus = createAsyncThunk(
  'PatternManagementData/updateAdminUserStatus',
  async (userData: object, { rejectWithValue }) => {
    try {
      const response = await updateTenantUserStatusAPI(userData);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// const getUserInsert = (userList) => {
//   return [
//     ...userList.map((entry) => {
//       return {
//         id: entry.email,
//         email: entry.email,
//         name: entry.name,
//         isEnabled: entry.is_enabled,
//         // role: {
        //   id: entry.roles.id,
        //   roleName: entry.roles.role_name
        // },
//         tenantId: entry.tenant_id
//       };
//     })
//   ];
// };

const getAssetUsersDTO = (masterList) => {
  return [
    ...masterList.map((entry) => {
      if (entry.id) {
        return entry;
      }
      return {
        id: entry.user_id,
        name: entry.asset_name,
        description: entry.asset_description,
        assetId: entry.asset_id,
        userId: entry.user_id
      };
    })
  ];
};

export const getUserAssetsData = createAsyncThunk(
  'PatternManagementData/getUserAssetsData',
  async (payload: any) => {
    const response = await getUserAssetsDataAPI(payload);
    return response;
  }
);

export const deleteAssetOfUser = createAsyncThunk(
  'PatternManagementData/deleteTenantUser',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await deleteTenantUserAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteUserAssets = createAsyncThunk(
  'PatternManagementData/deleteUserAssets',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await deleteUserAssetAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const patternSlice = createSlice({
  name: 'PatternManagementData',
  initialState,
  reducers: {
    setIsAdminListUpdated: (state: any, action) => {
      state.isOrgUserListUpdated = action.payload;
    },
    setIsError: (state: any, action) => {
      state.isError = action.payload;
      if (!action.payload) {
        state.errorMessage = '';
      }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getPatternByTenantIdData.pending, (state) => {
        state.loadingUserData = true;
        state.tenantPatternData = [];
      })
      .addCase(getPatternByTenantIdData.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        if (action.payload) {
          const tenantPatternData = action.payload;
          state.tenantPatternData = tenantPatternData;
        }
      })
      .addCase(getPatternByTenantIdData.rejected, (state) => {
        state.loadingUserData = false;
      })
      .addCase(addTenantUser.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(addTenantUser.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.isOrgUserListUpdated = true;
      })
      .addCase(addTenantUser.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(updateTenantUser.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(updateTenantUser.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.isOrgUserListUpdated = true;
      })
      .addCase(updateTenantUser.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(deleteTenantUser.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(deleteTenantUser.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.isOrgUserListUpdated = true;
      })
      .addCase(deleteTenantUser.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(updateTenantUserStatus.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(updateTenantUserStatus.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.isOrgUserListUpdated = true;
      })
      .addCase(updateTenantUserStatus.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(getUserAssetsData.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        if (action.payload) {
          const userAssetsData = getAssetUsersDTO(action.payload);
          state.userAssetsData = userAssetsData;
          state.isUserAssetsListUpdated = false;
        }
      })
      .addCase(deleteUserAssets.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.isUserAssetsListUpdated = true;
      });
  }
});

export const { setIsAdminListUpdated, setIsError } =
  patternSlice.actions;
export default patternSlice.reducer;
