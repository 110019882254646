import {
  Config,
  apiDelete,
  apiGet,
  apiPatch,
  apiPost,
  apiPut
} from 'src/shared/utils/api-request';

export const getAssetTypeAPI = async (tenantId: string) => {
  const config = {} as Config;
  config.custom = {
    setLoading: () => {}
  };
  const res = await apiGet(`/v1/asset-type/tenant/${tenantId}`);
  return res.data;
};

export const deleteAssetTypeAPI = async (payload: any) => {
  const res = await apiDelete(`/v1/asset-type/${payload.id}/tenant/${payload.tenantId}`, payload);
  return res.data;
};
// /api/v1/asset-type
export const addAssetTypeAPI = async (payload: any) => {
  const res = await apiPost(`/v1/asset-type`, payload);
  return res.data;
};

export const updateAssetTypeAPI = async (payload: any) => {
  const res = await apiPut(
    `/v1/asset-type/${payload.id}`, payload);
  return res.data;
};

export const deleteUserAssetAPI = async (payload: any) => {
  const res = await apiDelete(
    `organization/${payload.tenantId}/assets/${payload.assetId}/user/${payload.userId}`
  );
  return res.data;
};
