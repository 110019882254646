import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addOrganizationAPI,
  getOrganizationDataAPI,
  getTenantPermissionDetailsAPI,
  getTenantUserOrganizationDataAPI,
  updateOrganizationAPI,
  updateOrganizationPermissionAPI
} from '../apis/OrganizationApi';
import { OrganizationData, PermissionData } from '../models';
import { RootState } from 'src/store/reducer';

interface OrganizationState {
  organizationData: OrganizationData[];
  loadingOrganizationData: boolean;
  isOrganizationListUpdated: boolean;
  isError: boolean;
  errorMessage: string;
  permissionData: PermissionData;
  loadingPermissionDetails: boolean;
  loadingUpdatePermission: boolean;
}

const initialState: OrganizationState = {
  organizationData: [],
  loadingOrganizationData: false,
  isOrganizationListUpdated: false,
  isError: false,
  errorMessage: '',
  permissionData: null,
  loadingPermissionDetails: false,
  loadingUpdatePermission: false
};
export const selectOrganizationState = ({ organizationsData }: RootState) => ({
  organizationData: organizationsData?.organizationData,
  loadingOrganizationData: organizationsData?.loadingOrganizationData,
  isOrganizationListUpdated: organizationsData?.isOrganizationListUpdated,
  isError: organizationsData?.isError,
  errorMessage: organizationsData?.errorMessage,
  permissionData: organizationsData?.permissionData,
  loadingPermissionDetails: organizationsData?.loadingPermissionDetails,
  loadingUpdatePermission: organizationsData?.loadingUpdatePermission
});

export const getOrganizationData = createAsyncThunk(
  'organizationData/getOrganizationData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getOrganizationDataAPI();
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTenantUserOrganizationData = createAsyncThunk(
  'organizationData/getTenantUserOrganizationData',
  async (userId: string, { rejectWithValue }) => {
    try {
      return await getTenantUserOrganizationDataAPI(userId);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveOrganization = createAsyncThunk(
  'organizationData/saveOrganization',
  async (organization: object, { rejectWithValue }) => {
    try {
      const response = await addOrganizationAPI(organization);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateOrganization = createAsyncThunk(
  'organizationData/updateOrganization',
  async (organization: object, { rejectWithValue }) => {
    try {
      const response = await updateOrganizationAPI(organization);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateOrganizationPermission = createAsyncThunk(
  'organizationData/updateOrganizationPermission',
  async (permission: object, { rejectWithValue }) => {
    try {
      const response = await updateOrganizationPermissionAPI(permission);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// const getOrganizationListInsert = (organizationList) => {
//   return [
//     ...organizationList.map((entry) => {
//       return {
//         id: entry.id,
//         tenantName: entry.tenant_name,
//         code: entry.code,
//         isAssetSold: entry.isAssetSold
//       };
//     })
//   ];
// };

// const getOrganizationTenantListInsert = (organizationList) => {
//   return [
//     ...organizationList.map((entry) => {
//       return {
//         id: entry.tenant_id,
//         tenantName: entry.tenant_name,
//         code: entry.tenant_code,
//         isAssetSold: entry.isAssetSold
//       };
//     })
//   ];
// };
export const getTenantPermissionDetails = createAsyncThunk(
  'adminManagementData/getTenantPermissionDetails',
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await getTenantPermissionDetailsAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const organizationSlice = createSlice({
  name: 'organizationData',
  initialState,
  reducers: {
    setIsOrganizationListUpdated: (state: any, action) => {
      state.isOrganizationListUpdated = action.payload;
    },
    setIsError: (state: any, action) => {
      state.isError = action.payload;
      if (!action.payload) {
        state.errorMessage = '';
      }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getOrganizationData.pending, (state) => {
        state.loadingOrganizationData = true;
        state.organizationData = [];
      })
      .addCase(getOrganizationData.fulfilled, (state: any, action) => {
        state.loadingOrganizationData = false;
        state.isOrganizationListUpdated = false;
        if (action.payload) {
          const organizationData = action.payload;
          state.organizationData = organizationData;
        }
      })
      .addCase(getOrganizationData.rejected, (state, action: any) => {
        state.loadingOrganizationData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(
        getTenantUserOrganizationData.fulfilled,
        (state: any, action) => {
          state.loadingOrganizationData = false;
          if (action.payload) {
            const organizationData = action.payload;
            state.organizationData = organizationData;
          }
        }
      )
      .addCase(getTenantUserOrganizationData.rejected, (state, action: any) => {
        state.loadingOrganizationData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })

      .addCase(saveOrganization.pending, (state) => {
        state.loadingOrganizationData = true;
        state.isOrganizationListUpdated = false;
      })
      .addCase(saveOrganization.fulfilled, (state: any, action) => {
        state.loadingOrganizationData = false;
        state.isOrganizationListUpdated = true;
      })
      .addCase(saveOrganization.rejected, (state, action: any) => {
        state.loadingOrganizationData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(updateOrganization.pending, (state) => {
        state.loadingOrganizationData = true;
        state.isOrganizationListUpdated = false;
      })
      .addCase(updateOrganization.fulfilled, (state: any, action) => {
        state.loadingOrganizationData = false;
        state.isOrganizationListUpdated = true;
      })
      .addCase(updateOrganization.rejected, (state, action: any) => {
        state.loadingOrganizationData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(updateOrganizationPermission.pending, (state) => {
        state.loadingUpdatePermission = true;
      })
      .addCase(updateOrganizationPermission.fulfilled, (state: any, action) => {
        state.loadingUpdatePermission = false;
        state.isOrganizationListUpdated = true;
      })
      .addCase(updateOrganizationPermission.rejected, (state, action: any) => {
        state.loadingOrganizationData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(getTenantPermissionDetails.pending, (state) => {
        state.loadingPermissionDetails = true;
      })
      .addCase(getTenantPermissionDetails.fulfilled, (state: any, action) => {
        state.loadingPermissionDetails = false;
        state.permissionData = action.payload;
      });
  }
});

export const { setIsOrganizationListUpdated, setIsError } =
  organizationSlice.actions;
export default organizationSlice.reducer;
