import {
  apiGet,
  apiPatch,
  apiPost,
  apiPut
} from '../../../shared/utils/api-request';
import { ReportData } from '../models';


export const getReportDataAPI = async (tenantId: string) => {
  const res = await apiGet(`/v1/expence/tenant/${tenantId}`);
  return res.data;
};

export const findTransactionByTenantIdAPI = async (payload: any) => {
  const res = await apiGet(`/v1/transaction/tenant/${payload.tenantId}?startDate=${payload?.startDate?.valueOf()}&endDate=${payload?.endDate?.valueOf()}`);
  return res.data;
};

export const addReportDataAPI = async (payload: ReportData) => {
  const res = await apiPost(`/v1/expence/tenant/${payload.tenantId}`, payload);
  return res.data;
};

export const updateReportDataAPI = async (expence: any) => {
  const res = await apiPut(
    `/tenant/${expence.tenantId}/user/${expence.email}`,
    { roleId: expence.role }
  );
  return res.data;
};









