import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import {
  SERVER_API_URL
} from '../../shared/constants/constants';
import { Signout } from '../../shared/utils/signout';
import { apiConfig } from '../../api';
import { getAccessToken, getRefreshToken } from 'src/shared/utils/storage';
import { showLoader, hideLoader } from 'src/shared/utils/eventEmitter/Loader';
import { logoutSession } from 'src/shared/utils/eventEmitter/logoutEmitter';

const axiosInstance = axios.create({
  baseURL: apiConfig.baseUrl,
  headers: {
    'Content-Type': 'application/json'
  }
});
axiosInstance.interceptors.request.use(
  (confA: AxiosRequestConfig) => {
    const conf = confA as any;
    let accessToken = getAccessToken();
    if (confA?.url === '/auth/token/refresh') accessToken = getRefreshToken();
    else accessToken = getAccessToken();
    showLoader();
    conf.headers.authorization = `Bearer ${accessToken}`;
    return conf;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    hideLoader();
    return response;
  },
  (error) => {
    hideLoader();
    const originalRequest = error.config;

    if (
      error.response.status === 401 ||
      originalRequest.url === `${SERVER_API_URL}getaccesstoken`
    ) {
      logoutSession();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
