import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import organizationReducer from 'src/modules/Organization/services/organization.service';
import adminUserDataReducer from 'src/modules/AdminManagement/services/adminManagement.service';
import userManagementDataReducer from 'src/modules/UserManagement/services/userManagement.service';
import userReducer from 'src/auth/login/services/userSlice';
import storage from 'redux-persist/lib/storage';
import storeSlice from 'src/modules/Store/services/storeManagement.service';
import colorSlice from 'src/modules/Color/services/colorManagement.service';
import patternSlice from 'src/modules/Pattern/services/patternManagement.service';
import invoiceSlice from 'src/modules/Invoice/services/invoiceManagement.service';
import assetSlice from 'src/modules/Asset/services/assetManagement.service';
import assetgroupSlice from 'src/modules/Asset-group/services/asset-groupManagement.service';
import assettypeSlice from 'src/modules/Asset-type/services/asset-type_Management.service';
import BookingSlice from 'src/modules/BookingManagement/services/bookingManagement.service';
import expenceSlice from 'src/modules/expence/services/expenceManagement.service';
import firmSlice from 'src/modules/Firm/services/firmManagement.service';
import locationSlice from 'src/modules/Location/services/locationManagement.service';
import permissionSlice from 'src/modules/Permission/services/permissionManagement.service';
import permissionmasterSlice from 'src/modules/Permission-master/services/permisstionmasterManagement.service';
import roleSlice from 'src/modules/Role/services/roleManagement.service';
import reportSlice from 'src/modules/Report/services/reportManagement.service';
import sizeSlice from 'src/modules/Size/services/sizeManagement.service';
import stocktypeSlice from 'src/modules/Stock-type/services/userManagement.service';



const combinedReducer = combineReducers({
  user: userReducer,
  store: storeSlice,
  color: colorSlice,
  pattern: patternSlice,
  assetData: assetSlice,
  assetGroupData: assetgroupSlice,
  assettype: assettypeSlice,
  bookingManagementData: BookingSlice,
  expence: expenceSlice,
  firm: firmSlice,
  location: locationSlice,
  permission: permissionSlice,
  permissionmaster: permissionmasterSlice,
  role: roleSlice,
  report: reportSlice,
  size: sizeSlice,
  stocktype: stocktypeSlice,
  invoice: invoiceSlice,
  organizationsData: organizationReducer,
  adminUserData: adminUserDataReducer,
  userData: userReducer,
  userManagementData: userManagementDataReducer
});

export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer = (state: RootState, action: AnyAction) => {
  if (action.type.indexOf('/clearResults') > -1) {
    // this applies to all keys defined in persistConfig(s)
    storage.removeItem('persist:root');
    state = {} as RootState;
  }
  if (action.type === 'user/logout') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
