import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  deleteTenantUserAPI as deleteAssetAPI,
  getTenantAssetDataAPI,
  updateAssetAPI,
  addAssetAPI,
  updateTenantUserStatusAPI,
  getUserAssetsDataAPI,
  deleteUserAssetAPI,
  getFutureBookingByAssetIdAPI,
  getExpensesByAssetIdAPI
} from '../apis/assetManagementAPI';
import { RootState } from 'src/store/reducer';
import { AssetReq } from '../models';

interface AssetState {
  tenantAssetList: any[];
  loadingUserData: boolean;
  isOrgUserListUpdated: boolean;
  isError: boolean;
  errorMessage: string;
  bookingAssetsData: any;
  expenseAssetsData: any;
  isUserAssetsListUpdated: boolean;
}

const initialState: AssetState = {
  tenantAssetList: [],
  loadingUserData: false,
  isOrgUserListUpdated: false,
  isError: false,
  errorMessage: '',
  bookingAssetsData: [],
  expenseAssetsData: [],
  isUserAssetsListUpdated: false
};
export const selectorAssetState = ({
  assetData
}: RootState) => ({
  tenantAssetList: assetData?.tenantAssetList,
  loadingUserData: assetData?.loadingUserData,
  isOrgUserListUpdated: assetData?.isOrgUserListUpdated,
  isError: assetData?.isError,
  errorMessage: assetData?.errorMessage,
  bookingAssetsData: assetData?.bookingAssetsData,
  expenseAssetsData: assetData?.expenseAssetsData,
});
export const getAssetByTenantIdData = createAsyncThunk(
  'assetData/getOrganizationUserData',
  async (req: AssetReq) => {
    const response = await getTenantAssetDataAPI(req);
    return response;
  }
);

export const getFutureBookingByAssetId = createAsyncThunk(
  'assetData/getFutureBookingByAssetId',
  async (assetId: number) => {
    const response = await getFutureBookingByAssetIdAPI(assetId);
    return response;
  }
);

export const getExpensesByAssetId = createAsyncThunk(
  'assetData/getExpensesByAssetId',
  async (assetId: number) => {
    const response = await getExpensesByAssetIdAPI(assetId);
    return response;
  }
);

export const deleteAsset = createAsyncThunk(
  'assetData/deleteAsset',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await deleteAssetAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addAsset = createAsyncThunk(
  'assetData/addAsset',
  async (UserPayload: any, { rejectWithValue }) => {
    try {
      const response = await addAssetAPI(UserPayload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAsset = createAsyncThunk(
  'assetData/updateAsset',
  async (userData: object, { rejectWithValue }) => {
    try {
      const response = await updateAssetAPI(userData);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateTenantUserStatus = createAsyncThunk(
  'assetData/updateAdminUserStatus',
  async (userData: object, { rejectWithValue }) => {
    try {
      const response = await updateTenantUserStatusAPI(userData);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserAssetsData = createAsyncThunk(
  'assetData/getUserAssetsData',
  async (payload: any) => {
    const response = await getUserAssetsDataAPI(payload);
    return response;
  }
);


export const deleteUserAssets = createAsyncThunk(
  'assetData/deleteUserAssets',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await deleteUserAssetAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const assetSlice = createSlice({
  name: 'assetData',
  initialState,
  reducers: {
    setIsAdminListUpdated: (state: any, action) => {
      state.isOrgUserListUpdated = action.payload;
    },
    setIsError: (state: any, action) => {
      state.isError = action.payload;
      if (!action.payload) {
        state.errorMessage = '';
      }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAssetByTenantIdData.pending, (state) => {
        state.loadingUserData = true;
        state.tenantAssetList = [];
      })
      .addCase(getAssetByTenantIdData.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        if (action.payload) {
          const tenantAssetList = action.payload;
          state.tenantAssetList = tenantAssetList;
        }
      })
      .addCase(getAssetByTenantIdData.rejected, (state) => {
        state.loadingUserData = false;
      })
      .addCase(getFutureBookingByAssetId.pending, (state) => {
        state.loadingUserData = true;
        state.bookingAssetsData = [];
      })
      .addCase(getFutureBookingByAssetId.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        if (action.payload) {
          const bookingAssetsData = action.payload;
          state.bookingAssetsData = bookingAssetsData;
        }
      })
      .addCase(getFutureBookingByAssetId.rejected, (state) => {
        state.loadingUserData = false;
      })
      .addCase(getExpensesByAssetId.pending, (state) => {
        state.loadingUserData = true;
        state.expenseAssetsData = [];
      })
      .addCase(getExpensesByAssetId.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        if (action.payload) {
          const expenseAssetsData = action.payload;
          state.expenseAssetsData = expenseAssetsData;
        }
      })
      .addCase(getExpensesByAssetId.rejected, (state) => {
        state.loadingUserData = false;
      })
      .addCase(addAsset.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(addAsset.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.isOrgUserListUpdated = true;
      })
      .addCase(addAsset.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(updateAsset.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(updateAsset.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.isOrgUserListUpdated = true;
      })
      .addCase(updateAsset.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(deleteAsset.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(deleteAsset.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.isOrgUserListUpdated = true;
      })
      .addCase(deleteAsset.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(updateTenantUserStatus.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(updateTenantUserStatus.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.isOrgUserListUpdated = true;
      })
      .addCase(updateTenantUserStatus.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(deleteUserAssets.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.isUserAssetsListUpdated = true;
      });
  }
});

export const { setIsAdminListUpdated, setIsError } =
  assetSlice.actions;
export default assetSlice.reducer;
