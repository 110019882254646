import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  UserData,
  UserLoginDetails,
  DecodedToken
} from '../models/login';
import { ROLES, USER_TYPE } from '../../../shared/constants/constants';
import jwt_decode from 'jwt-decode';
import { loginAPI, loginDevEnvAPI, logOutAPI } from '../apis/login';
import { OrganizationData } from '../../../modules/Organization/models';
import { RootState } from '../../../store/reducer';
import {
  setAccessToken,
  setDecodedToken,
  setRefreshToken
} from '../../../shared/utils/storage';

const initialState = {
  // used in v3
  loginDomain: '',
  loginUserType: '',
  userData: <UserData>{},
  userInfo: <UserLoginDetails>{},
  authToken: '',
  loginUserRole: '',
  tenantUserRoles: <any>{},
  userRoleMap: {},
  loading: false,
  error: false,
  errorObj: <any>{},
  decodedToken: <DecodedToken>{},
  isLoginFailed: false,
  selectedOrganization: <OrganizationData>{}
};

export const selectUserState = ({ user }: RootState) => ({
  userData: user?.userData,
  userInfo: user?.userInfo,
  loginUserType: user?.loginUserType,
  authToken: user?.authToken,
  loginUserRole: user?.loginUserRole,
  tenantUserRoles: user?.tenantUserRoles,
  loading: user?.loading,
  error: user?.error,
  errorObj: user?.errorObj,
  loginDomain: user?.loginDomain,
  decodedToken: user?.decodedToken,
  isLoginFailed: user?.isLoginFailed,
  selectedOrganization: user?.selectedOrganization
});

export const getLoginDetails = createAsyncThunk(
  'user/getLoginDetails',
  async (userData: object, { rejectWithValue }) => {
    try {
      return await loginAPI(userData);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDevEnvLoginDetails = createAsyncThunk(
  'user/getDevEnvLoginDetails',
  async (userData: object, { rejectWithValue }) => {
    try {
      return await loginDevEnvAPI(userData);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLogOutUser = createAsyncThunk(
  'user/getLogout',
  async (userData: object, { rejectWithValue }) => {
    try {
      return await logOutAPI(userData);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    clearResults() {},
    setData: (state, action) => {
      state.userData = action.payload;
    },
    setUserRole: (state, action) => {
      state.loginUserRole = action.payload;
    },
    resetData: (state) => {
      state.userData = <UserData>{};
      state.userInfo = <UserLoginDetails>{};
      state.loginUserRole = '';
      setAuthToken('');
    },
    setIsErrorUpdated: (state, action) => {
      state.isLoginFailed = action.payload;
      state.errorObj = '';
      state.error = action.payload;
    },
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
    setselectedOrganization: (state, action) => {
      state.selectedOrganization = action.payload;
    },
    setUserAndAccessTokenData: (state, action) => {
      state.userData = action.payload.userData;
      state.authToken = action.payload.token;
    },
    setLoginProvider: (state, action) => {
      state.loginDomain = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLoginDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorObj = {};
        state.isLoginFailed = false;
        state.userInfo = {};
        state.userData = {} as UserData;
        state.userData = <UserData>{};
      })
      .addCase(getLoginDetails.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        state.userInfo = action.payload;
        state.userData = action.payload;
        state.authToken = action.payload.access;
        setAccessToken(action.payload.token);
        const decodedToken: DecodedToken = jwt_decode(action.payload.token);
        state.decodedToken = decodedToken;
        setDecodedToken(decodedToken);
        setRefreshToken(action.payload.refreshToken);

        state.decodedToken = jwt_decode(action.payload.token);
        if (state.decodedToken.role === ROLES.ADMIN_OPERATOR) {
          state.loginUserRole = ROLES.ADMIN_OPERATOR;
          state.loginUserType = USER_TYPE.ADMIN_USER;
        } else if (state.decodedToken.role === ROLES.SUPER_ADMIN) {
          state.loginUserRole = ROLES.SUPER_ADMIN;
          state.loginUserType = USER_TYPE.ADMIN_USER;
        } else if (state.decodedToken.role.type === USER_TYPE.TENANT_USER) {
          state.loginUserType = USER_TYPE.TENANT_USER;
        }
        state.tenantUserRoles = state.decodedToken.role;
        state.isLoginFailed = false;
      })
      .addCase(getLoginDetails.rejected, (state, action: any) => {
        state.loading = false;
        state.isLoginFailed = true;
        state.error = true;
        state.errorObj.message = action.payload?.message;
        resetData();
      })

      .addCase(getLogOutUser.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorObj = {};
        state.isLoginFailed = false;
      })
      .addCase(getLogOutUser.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.userInfo = null;
          state.userData = null;
          state.authToken = null;
          state.decodedToken = null;
          state.loginUserRole = null;
          state.tenantUserRoles = null;
          state.isLoginFailed = false;
          setAccessToken(null);
          setDecodedToken(null);
          setRefreshToken(null);

        } else {
          state.isLoginFailed = false;
          state.error = false;
          resetData();
        }
      })
      .addCase(getLogOutUser.rejected, (state, action: any) => {
        state.loading = false;
        state.isLoginFailed = true;
        state.error = true;
        state.errorObj.message = action.payload?.message;
        resetData();
      })
      .addCase(getDevEnvLoginDetails.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = false;
        state.userInfo = action.payload;
        state.userData = action.payload;
        state.authToken = action.payload.access;
        //   setUpInterceptor(action.payload.token);
        setAccessToken(action.payload.token);
        const decodedToken: DecodedToken = jwt_decode(action.payload.token);
        state.decodedToken = decodedToken;
        setDecodedToken(decodedToken);
        setRefreshToken(action.payload.refreshToken);
        state.decodedToken = jwt_decode(action.payload.token);
        if (state.decodedToken.role === ROLES.ADMIN_OPERATOR) {
          state.loginUserRole = ROLES.ADMIN_OPERATOR;
          state.loginUserType = USER_TYPE.ADMIN_USER;
        } else if (state.decodedToken.role === ROLES.SUPER_ADMIN) {
          state.loginUserRole = ROLES.SUPER_ADMIN;
          state.loginUserType = USER_TYPE.ADMIN_USER;
        } else if (state.decodedToken.role.type === USER_TYPE.TENANT_USER) {
          state.loginUserType = USER_TYPE.TENANT_USER;
        }
        state.tenantUserRoles = state.decodedToken.role;
        state.isLoginFailed = false;
      });
  }
});

export const {
  setData,
  resetData,
  setAuthToken,
  setIsErrorUpdated,
  setselectedOrganization,
  setUserAndAccessTokenData,
  setLoginProvider,
  clearResults,
  setUserRole
} = userSlice.actions;

export default userSlice.reducer;
