import {
  apiGet,
  apiPatch,
  apiPost,
  apiPut
} from '../../../shared/utils/api-request';
import { Expence } from '../models';

export const getExpenceDataAPI = async (tenantId: string) => {
  const res = await apiGet(`/v1/expence/tenant/${tenantId}`);
  return res.data;
};

export const addExpenceAPI = async (payload: Expence) => {
  const res = await apiPost(`/v1/expence/tenant/${payload.tenantId}`, payload);
  return res.data;
};

export const updateExpenceAPI = async (expence: any) => {
  const res = await apiPut(
    `/tenant/${expence.tenantId}/user/${expence.email}`,
    { roleId: expence.role }
  );
  return res.data;
};









