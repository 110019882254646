import {
  Config,
  apiDelete,
  apiGet,
  apiPatch,
  apiPost,
  apiPut
} from 'src/shared/utils/api-request';

export const getSizeDataAPI = async ( ) => {
  const config = {} as Config;
  config.custom = {
    setLoading: () => {}
  };
  const res = await apiGet(`v1/size`);
  return res.data;
};  

export const deleteTenantUserAPI = async (payload: any) => {
  const res = await apiDelete(`/tenant/user/${payload.email}`, payload);
  return res.data;
};

export const addTenantUserAPI = async (payload: any) => {
  const res = await apiPost(`/v1/size`, {
    status: payload.status,
    name: payload.name,
     });
  return res.data;
};

export const updateTenantUserAPI = async (payload) => {
  const res = await apiPut(
    `/v1/size/${payload.id}`,
    {  status: payload.status,
      name: payload.name,
        }
  );
  return res.data;
};

export const updateTenantUserStatusAPI = async (userData: any) => {
  const res = await apiPatch(
    `/tenant/${userData.tenantId}/user/${userData.email}/enable`,
    { value: userData.value }
  );
  return res.data;
};

export const getUserAssetsDataAPI = async (payload) => {
  const config = {} as Config;
  config.custom = {
    setLoading: () => {}
  };
  const res = await apiGet(
    `/organization/${payload.orgId}/user/${payload.user}/assets`
  );
  return res.data;
};

export const deleteUserAssetAPI = async (payload: any) => {
  const res = await apiDelete(
    `organization/${payload.tenantId}/assets/${payload.assetId}/user/${payload.userId}`
  );
  return res.data;
};
