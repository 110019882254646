import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  deleteTenantUserAPI,
  getInvoiceByTenantIdDataAPI,
  updateTenantUserAPI,
  addTenantUserAPI,
  updateTenantUserStatusAPI,
  getUserAssetsDataAPI,
  deleteUserAssetAPI
} from '../apis/InvoiceManagementAPI';
import { RootState } from 'src/store/reducer';
import { InvoiceData } from '../models';

interface InvoiceManagementState {
  tenantInvoiceData: InvoiceData[];
  loadingUserData: boolean;
  isOrgUserListUpdated: boolean;
  isError: boolean;
  errorMessage: string;
  userAssetsData: any;
  isUserAssetsListUpdated: boolean;
}

const initialState: InvoiceManagementState = {
  tenantInvoiceData: [],
  loadingUserData: false,
  isOrgUserListUpdated: false,
  isError: false,
  errorMessage: '',
  userAssetsData: [],
  isUserAssetsListUpdated: false
};
export const selectorInvoiceManagementState = ({
  invoice
}: RootState) => ({
  tenantInvoiceData:invoice?.tenantInvoiceData,
   loadingUserData: invoice?.loadingUserData,
   isOrgUserListUpdated: invoice?.isOrgUserListUpdated,
   isError:invoice?.isError,
   errorMessage:invoice?.errorMessage,
   userAssetsData: invoice?.userAssetsData,
   isUserAssetsListUpdated:invoice?.isUserAssetsListUpdated,
});
export const getInvoiceByTenantId
 = createAsyncThunk(
  'InvoiceManagementData/getInvoiceByTenantId',
  async (orgId: string) => {
    const response = await getInvoiceByTenantIdDataAPI(orgId);
    return response;
  }
);

export const deleteTenantUser = createAsyncThunk(
  'InvoiceManagementData/deleteTenantUser',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await deleteTenantUserAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
 
export const addTenantUser = createAsyncThunk(
  'InvoiceManagementData/addTenantUser',
  async (UserPayload: any, { rejectWithValue }) => {
    try {
      const response = await addTenantUserAPI(UserPayload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateTenantUser = createAsyncThunk(
  'InvoiceManagementData/updateTenantUser',
  async (userData: object, { rejectWithValue }) => {
    try {
      const response = await updateTenantUserAPI(userData);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateTenantUserStatus = createAsyncThunk(
  'InvoiceManagementData/updateAdminUserStatus',
  async (userData: object, { rejectWithValue }) => {
    try {
      const response = await updateTenantUserStatusAPI(userData);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

 

const getAssetUsersDTO = (masterList) => {
  return [
    ...masterList.map((entry) => {
      if (entry.id) {
        return entry;
      }
      return {
        id: entry.user_id,
        name: entry.asset_name,
        description: entry.asset_description,
        assetId: entry.asset_id,
        userId: entry.user_id
      };
    })
  ];
};

export const getUserAssetsData = createAsyncThunk(
  'InvoiceManagementData/getUserAssetsData',
  async (payload: any) => {
    const response = await getUserAssetsDataAPI(payload);
    return response;
  }
);

export const deleteAssetOfUser = createAsyncThunk(
  'InvoiceManagementData/deleteTenantUser',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await deleteTenantUserAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteUserAssets = createAsyncThunk(
  'InvoiceManagementData/deleteUserAssets',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await deleteUserAssetAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const invoiceSlice = createSlice({
  name: 'InvoiceManagementData',
  initialState,
  reducers: {
    setIsAdminListUpdated: (state: any, action) => {
      state.isOrgUserListUpdated = action.payload;
    },
    setIsError: (state: any, action) => {
      state.isError = action.payload;
      if (!action.payload) {
        state.errorMessage = '';
      }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getInvoiceByTenantId.pending, (state) => {
        state.loadingUserData = true;
        state.orgUserData = [];
      })
      .addCase(getInvoiceByTenantId.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        if (action.payload) {
          // let orgUserData = getUserInsert(action.payload);
          // state.orgUserData = orgUserData;
          const tenantInvoiceData = action.payload;
          state.tenantInvoiceData = tenantInvoiceData;
        }
      })
      .addCase(getInvoiceByTenantId.rejected, (state) => {
        state.loadingUserData = false;
      })
      .addCase(addTenantUser.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(addTenantUser.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.isOrgUserListUpdated = true;
      })
      .addCase(addTenantUser.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(updateTenantUser.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(updateTenantUser.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.isOrgUserListUpdated = true;
      })
      .addCase(updateTenantUser.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(deleteTenantUser.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(deleteTenantUser.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.isOrgUserListUpdated = true;
      })
      .addCase(deleteTenantUser.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(updateTenantUserStatus.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(updateTenantUserStatus.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.isOrgUserListUpdated = true;
      })
      .addCase(updateTenantUserStatus.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(getUserAssetsData.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        if (action.payload) {
          const userAssetsData = getAssetUsersDTO(action.payload);
          state.userAssetsData = userAssetsData;
          state.isUserAssetsListUpdated = false;
        }
      })
      .addCase(deleteUserAssets.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.isUserAssetsListUpdated = true;
      });
  }
});

export const { setIsAdminListUpdated, setIsError } =
invoiceSlice.actions;
export default invoiceSlice.reducer;
