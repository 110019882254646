import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getTenantBookingDataAPI,
  addBookingAPI,
  findBookingByIdAPI,
  updateBookingAssetAPI,
  collectRentAPI,
  depositeUpdateAPI,
  cancelBookingAPI
} from '../apis/BookingManagementAPI';
import { Booking } from '../models';
import { RootState } from 'src/store/reducer';
import { ERROR_MESSAGE } from 'src/shared/constants/constants';

interface BookingManagementState {
  bookingData: Booking[];
  booking: Booking;
  selectedBokingData: Booking;
  isBookingListUpdated: boolean;
  isBookingUpdated: boolean,
  isBookingSave: boolean,
  errorMessage: string;
  isError: boolean;
}

const initialState: BookingManagementState = {
  bookingData: [],
  booking: null,
  isBookingListUpdated: false,
  isBookingSave: false,
  isBookingUpdated: false,
  isError: false,
  selectedBokingData: null,
  errorMessage: ''
};
export const selectorBookingManagementState = ({
  bookingManagementData
}: RootState) => ({
  bookingData: bookingManagementData?.bookingData,
  booking: bookingManagementData?.booking,
  isBookingListUpdated: bookingManagementData?.isBookingListUpdated,
  isError: bookingManagementData?.isError,
  isBookingUpdated: bookingManagementData?.isBookingUpdated,
  isBookingSave: bookingManagementData?.isBookingSave,
  errorMessage: bookingManagementData?.errorMessage,
  selectedBokingData: bookingManagementData.selectedBokingData,
});

export const getTenantBookingData = createAsyncThunk(
  'bookingManagementData/getOrganizationUserData',
  async (payload: any) => {
    const response = await getTenantBookingDataAPI(payload);
    return response;
  }
);

export const addBooking = createAsyncThunk(
  'bookingData/addBooking',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await addBookingAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const findBookingById = createAsyncThunk(
  'bookingData/findBookingById',
  async (bookingId: number, { rejectWithValue }) => {
    try {
      const response = await findBookingByIdAPI(bookingId);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateBookingAsset = createAsyncThunk(
  'bookingData/updateBookingAsset',
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await updateBookingAssetAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const collectRent = createAsyncThunk(
  'bookingData/collectRent',
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await collectRentAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const cancelBooking = createAsyncThunk(
  'bookingData/cancelBooking',
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await cancelBookingAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const depositeUpdate = createAsyncThunk(
  'bookingData/depositeUpdate',
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await depositeUpdateAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const BookingSlice = createSlice({
  name: 'bookingData',
  initialState,
  reducers: {

    setIsError: (state: any, action) => {
      state.isError = action.payload;
      if (!action.payload) {
        state.errorMessage = '';
      }
    },
    setIsBookingSave: (state: any, action) => {
      state.isBookingSave = action.payload;
    },

    setIsBookingUpdated: (state: any, action) => {
      state.isBookingUpdated = action.payload;
    },

    setSelectedBooking: (state: any, action) => {
      state.selectedBokingData = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getTenantBookingData.pending, (state) => {
        state.bookingData = [];
      })
      .addCase(getTenantBookingData.fulfilled, (state: any, action) => {
        if (action.payload) {
          const bookingData = action.payload;
          state.bookingData = bookingData;
        }
      })
      .addCase(getTenantBookingData.rejected, (state) => {
      })
      .addCase(findBookingById.pending, (state) => {
        state.bookingData = [];
      })
      .addCase(findBookingById.fulfilled, (state: any, action) => {
        if (action.payload) {
          const booking = action.payload;
          state.booking = booking;
        }
      })
      .addCase(findBookingById.rejected, (state) => {
      })
      .addCase(addBooking.pending, (state) => {
      })
      .addCase(addBooking.fulfilled, (state: any, action) => {
        state.isBookingListUpdated = true;
        state.isBookingSave = true;
        state.selectedBokingData = action.payload;
      })
      .addCase(addBooking.rejected, (state, action: any) => {
        state.isError = true;
        state.errorMessage = action?.payload?.message && typeof action?.payload?.message === 'string'
          ? action?.payload?.message
          : ERROR_MESSAGE;
      })
      .addCase(updateBookingAsset.pending, (state) => {
      })
      .addCase(updateBookingAsset.fulfilled, (state: any, action) => {
        if (action.payload) {
          const booking = action.payload;
          state.booking = booking;
        }
      })
      .addCase(updateBookingAsset.rejected, (state, action: any) => {
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(collectRent.pending, (state) => {
      })
      .addCase(collectRent.fulfilled, (state: any, action) => {
        if (action.payload) {
          const booking = action.payload;
          state.booking = booking;
        }
      })
      .addCase(collectRent.rejected, (state, action: any) => {
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })

      .addCase(depositeUpdate.pending, (state) => {
      })
      .addCase(depositeUpdate.fulfilled, (state: any, action) => {
        if (action.payload) {
          const booking = action.payload;
          state.booking = booking;
        }
      })
      .addCase(depositeUpdate.rejected, (state, action: any) => {
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      }).addCase(cancelBooking.pending, (state) => {
      })
      .addCase(cancelBooking.fulfilled, (state: any, action) => {
        if (action.payload) {
          const booking = action.payload;
          state.booking = booking;
        }
        state.isBookingUpdated = true;
      })
      .addCase(cancelBooking.rejected, (state, action: any) => {
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : 'Unable to handle req now';
      })
  }
});

export const { setIsError, setSelectedBooking, setIsBookingSave, setIsBookingUpdated } =
  BookingSlice.actions;
export default BookingSlice.reducer;
