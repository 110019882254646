import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  deleteTenantUserAPI,
 // getSizeByTenantIdDataAPI,
 getSizeDataAPI,
  updateTenantUserAPI,
  addTenantUserAPI,
  updateTenantUserStatusAPI,
  getUserAssetsDataAPI,
  deleteUserAssetAPI
} from '../apis/sizeManagementAPI';
import { AssetUser } from '../models';
import { RootState } from 'src/store/reducer';

interface UserManagementState {
  orgUserData: AssetUser[];
  loadingUserData: boolean;
  isOrgUserListUpdated: boolean;
  isError: boolean;
  errorMessage: string;
  userAssetsData: any;
  isUserAssetsListUpdated: boolean;
}

const initialState: UserManagementState = {
  orgUserData: [],
  loadingUserData: false,
  isOrgUserListUpdated: false,
  isError: false,
  errorMessage: '',
  userAssetsData: [],
  isUserAssetsListUpdated: false
};
export const selectorUserManagementState = ({
  size
}: RootState) => ({
  orgUserData: size?.orgUserData,
  loadingUserData:size?.loadingUserData,
  isOrgUserListUpdated:size?.isOrgUserListUpdated,
  isError: size?.isError,
  errorMessage: size?.errorMessage,
  userAssetsData: size?.userAssetsData,
  isUserAssetsListUpdated: size?.isUserAssetsListUpdated,
});
export const getSizeByTenantIdData = createAsyncThunk(
  'SizeManagementData/getSizeByTenantIdData',
  async () => {
    const response = await getSizeDataAPI();  
    return response;
  }
);

export const deleteTenantUser = createAsyncThunk(
  'SizeManagementData/deleteTenantUser',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await deleteTenantUserAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addTenantUser = createAsyncThunk(
  'SizeManagementData/addTenantUser',
  async (UserPayload: any, { rejectWithValue }) => {
    try {
      const response = await addTenantUserAPI(UserPayload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateTenantUser = createAsyncThunk(
  'SizeManagementData/updateTenantUser',
  async (userData: object, { rejectWithValue }) => {
    try {
      const response = await updateTenantUserAPI(userData);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateTenantUserStatus = createAsyncThunk(
  'SizeManagementData/updateAdminUserStatus',
  async (userData: object, { rejectWithValue }) => {
    try {
      const response = await updateTenantUserStatusAPI(userData);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// const getUserInsert = (userList) => {
//   return [
//     ...userList.map((entry) => {
//       return {
//         id: entry.email,
//         email: entry.email,
//         name: entry.name,
//         isEnabled: entry.is_enabled,
//         role: {
//           id: entry.roles.id,
//           roleName: entry.roles.role_name
//         },
//         tenantId: entry.tenant_id
//       };
//     })
//   ];
// };

// const getAssetUsersDTO = (masterList) => {
//   return [
//     ...masterList.map((entry) => {
//       if (entry.id) {
//         return entry;
//       }
//       return {
//         id: entry.user_id,
//         name: entry.asset_name,
//         description: entry.asset_description,
//         assetId: entry.asset_id,
//         userId: entry.user_id
//       };
//     })
//   ];
// };

export const getUserAssetsData = createAsyncThunk(
  'SizeManagementData/getUserAssetsData',
  async (payload: any) => {
    const response = await getUserAssetsDataAPI(payload);
    return response;
  }
);

export const deleteAssetOfUser = createAsyncThunk(
  'SizeManagementData/deleteTenantUser',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await deleteTenantUserAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteUserAssets = createAsyncThunk(
  'SizeManagementData/deleteUserAssets',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await deleteUserAssetAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const sizeSlice = createSlice({
  name: 'sizeManagementData',
  initialState,
  reducers: {
    setIsAdminListUpdated: (state: any, action) => {
      state.isOrgUserListUpdated = action.payload;
    },
    setIsError: (state: any, action) => {
      state.isError = action.payload;
      if (!action.payload) {
        state.errorMessage = '';
      }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getSizeByTenantIdData.pending, (state) => {
        state.loadingUserData = true;
        state.orgUserData = [];
      })
      .addCase(getSizeByTenantIdData.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        if (action.payload) {
          const orgUserData = action.payload;
          state.orgUserData = orgUserData;
        }
      })
      .addCase(getSizeByTenantIdData.rejected, (state) => {
        state.loadingUserData = false;
      })
      .addCase(addTenantUser.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(addTenantUser.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.isOrgUserListUpdated = true;
      })
      .addCase(addTenantUser.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(updateTenantUser.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(updateTenantUser.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.isOrgUserListUpdated = true;
      })
      .addCase(updateTenantUser.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(deleteTenantUser.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(deleteTenantUser.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.isOrgUserListUpdated = true;
      })
      .addCase(deleteTenantUser.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(updateTenantUserStatus.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(updateTenantUserStatus.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.isOrgUserListUpdated = true;
      })
      .addCase(updateTenantUserStatus.rejected, (state, action: any) => {
        state.loadingUserData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(getUserAssetsData.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        if (action.payload) {
          const userAssetsData = action.payload;
          state.userAssetsData = userAssetsData;
          state.isUserAssetsListUpdated = false;
        }
      })
      .addCase(deleteUserAssets.fulfilled, (state: any, action) => {
        state.loadingUserData = false;
        state.isUserAssetsListUpdated = true;
      });
  }
});

export const { setIsAdminListUpdated, setIsError } =
  sizeSlice.actions;
export default sizeSlice.reducer;
