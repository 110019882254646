import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';
import SidebarLayout from 'src/core/layout/SidebarLayout';
import BaseLayout from 'src/core';
import { GuardedRoute } from './guarded-routes';
import * as MODULE from './../shared/constants/constants';
import { SuspenseLoader } from 'src/shared/components/index';
import * as ROUTES from '../shared/constants/routes';

const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Pages

const Login = Loader(lazy(() => MODULE.LOGIN));
const ErrorPage = Loader(lazy(() => MODULE.ERROR_PAGE));

// Dashboards

const Organazation = Loader(lazy(() => MODULE.ORGANIZATION));
const AdminManagement = Loader(lazy(() => MODULE.ADMIN_MANAGEMENT));
const UserManagement = Loader(lazy(() => MODULE.USER_MANAGEMENT));
const BookingManagement = Loader(lazy(() => MODULE.BOOKING_MANAGEMENT));
const BookingView = Loader(lazy(() => MODULE.BOOKING_VIEW));
const Invoice = Loader(lazy(() => MODULE.INVOICE_MANAGEMENT));
// const Store = Loader(lazy(() => MODULE.STORE_MANAGEMENT));
const expence = Loader(lazy(() => MODULE.EXPENCE_MANAGEMENT));
const Asset = Loader(lazy(() => MODULE.ASSET_MANAGEMENT));
const AssetSL = Loader(lazy(() => MODULE.ASSET_SL_MANAGEMENT));
const AssetDetails = Loader(lazy(() => MODULE.ASSET_DETAILS));
const Assetgroup = Loader(lazy(() => MODULE.ASSET_GROUP_MANAGEMENT));
const Assettype = Loader(lazy(() => MODULE.ASSET_TYPE_MANAGEMENT));
// const Color = Loader(lazy(() => MODULE.COLOR_MANAGEMENT));
// const Firm = Loader(lazy(() => MODULE.FIRM_MANAGEMENT));
// const Location = Loader(lazy(() => MODULE.LOCATION_MANAGEMENT));
// const Pattern = Loader(lazy(() => MODULE.PATTERN_MANAGEMENT));
// const Permission_master = Loader(lazy(() => MODULE.PERMISSION_MASTER_MANAGEMENT));
// const Permission = Loader(lazy(() => MODULE.PERMISSION_MANAGEMENT));
const Report = Loader(lazy(() => MODULE.REPORT_MANAGEMENT));
// const Role = Loader(lazy(() => MODULE.ROLE_MANAGEMENT));
// const Size = Loader(lazy(() => MODULE.SIZE_MANAGEMENT));
// const Stock_type = Loader(lazy(() => MODULE.STOCK_TYPE_MANAGEMENT));
 
const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: ROUTES.LOGIN,
        element: <Login />
      }
    ]
  },
  {
    path: '',
    element: <BaseLayout className="admin-login" />,
    children: [
      {
        path: ROUTES.ADMIN_LOGIN,
        element: <Login />
      }
    ]
  },
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: `${ROUTES.DASHBOARD}`,
        element: (
          <Navigate
            to={`${ROUTES.ADMIN_LOGIN}${ROUTES.ORGANIZATION}`}
            replace
          />
        )
      },
      {
        path: `${ROUTES.ADMIN_LOGIN}${ROUTES.ORGANIZATION}`,
        element: <GuardedRoute component={Organazation} />
      },
      {
        path: `${ROUTES.TENANT_LOGIN}${ROUTES.ORGANIZATION}`,
        element: <GuardedRoute component={Organazation} />
      },
      {
        path: `${ROUTES.ADMIN_LOGIN}${ROUTES.ADMIN_MANAGEMENT}`,
        element: <GuardedRoute component={AdminManagement} />
      },
      {
        path: `${ROUTES.USER_MANAGEMENT}`,
        element: <GuardedRoute component={UserManagement} />
      }
      ,
      {
        path: `${ROUTES.BOOKING_MANAGEMENT}`,
        element: <GuardedRoute component={BookingManagement} />
      },
      {
        path: `${ROUTES.BOOKING_VIEW}`,
        element: <GuardedRoute component={BookingView} />
      }
      ,
      {
        path: `${ROUTES.INVOICE_MANAGEMENT}`,
        element: <GuardedRoute component={Invoice} />
      }
      ,
      // {
      //   path: `${ROUTES.STORE_MANAGEMENT}`,
      //   element: <GuardedRoute component={Store} />
      // }
      ,
      {
        path: `${ROUTES.EXPENCE_MANAGEMENT}`,
        element: <GuardedRoute component={expence} />
      },
      {
        path: `${ROUTES.ASSET_MANAGEMENT}`,
        element: <GuardedRoute component={Asset} />
      },
      {
        path: `${ROUTES.SOLD_ASSET_MANAGEMENT}`,
        element: <GuardedRoute component={AssetSL} />
      },
      {
        path: `${ROUTES.ASSET_DETAILS}`,
        element: <GuardedRoute component={AssetDetails} />
      },
      {
        path: `${ROUTES.ASSET_GROUP_MANAGEMENT}`,
        element: <GuardedRoute component={Assetgroup} />
      },
      {
        path: `${ROUTES.ASSET_TYPE_MANAGEMENT}`,
        element: <GuardedRoute component={Assettype} />
      }
      ,
      // {
      //   path: `${ROUTES.COLOR_MANAGEMENT}`,
      //   element: <GuardedRoute component={Color} />
      // },
      // {
      //   path: `${ROUTES.FIRM_MANAGEMENT}`,
      //   element: <GuardedRoute component={Firm} />
      // },
      // {
      //   path: `${ROUTES.LOCATION_MANAGEMENT}`,
      //   element: <GuardedRoute component={Location} />
      // },
     // {
      //   path: `${ROUTES.PATTERN_MANAGEMENT}`,
      //   element: <GuardedRoute component={Pattern} />
      // },
      // {
      //   path: `${ROUTES.PERMISSION_MASTER_MANAGEMENT}`,
      //   element: <GuardedRoute component={Permission_master} />
      // }
      // ,
      // {
      //   path: `${ROUTES.PERMISSION_MANAGEMENT}`,
      //   element: <GuardedRoute component={Permission} />
      // },
      {
        path: `${ROUTES.REPORT_MANAGEMENT}`,
        element: <GuardedRoute component={Report} />
      },
      // {
      //   path: `${ROUTES.ROLE_MANAGEMENT}`,
      //   element: <GuardedRoute component={Role} />
      // },
      // {
      //   path: `${ROUTES.SIZE_MANAGEMENT}`,
      //   element: <GuardedRoute component={Size} />
      // },
      // {
      //   path: `${ROUTES.STOCK_TYPE_MANAGEMENT}`,
      //   element: <GuardedRoute component={Stock_type} />
      // }
    ]
  }, 
  {
    path: ROUTES.ERROR_PAGE,
    element: <ErrorPage />
  }
];

export default routes;
