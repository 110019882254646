import React, { useEffect, useState } from 'react';
import { CombinedProps } from './services/datepickerInterface';
import { Box, FormHelperText } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker as MuiXDatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
interface DatePickerProps {
  maxDate: string | Date | Dayjs;
  minDate: string | Date | Dayjs;
}

export const DatePicker = ({
  required = false,
  helperText,
  label,
  disableFuture = false,
  disablePast = false,
  maxDate,
  minDate,
  value,
  defaultValue,
  onChange,
  ...props
}: CombinedProps & DatePickerProps) => {
  const maxAllowedDate: string | Date | Dayjs = maxDate;
  const minAllowedDate: string | Date | Dayjs = minDate;

  return (
    <Box className="">
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <MuiXDatePicker
          className="w-100"
          {...props}
          label={required ? `${label} *` : label}
          maxDate={maxAllowedDate ? dayjs(maxAllowedDate) : null}
          minDate={minAllowedDate ? dayjs(minAllowedDate) : null}
          defaultValue={defaultValue ? defaultValue : null}
          value={value ? value : null}
          views={props?.views}
          disableFuture={disableFuture}
          disablePast={disablePast}
          onChange={onChange}
        />
      </LocalizationProvider>
      <FormHelperText className="errorState">{helperText}</FormHelperText>
    </Box>
  );
};
