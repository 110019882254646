import * as sidebarmenu from './sidebarmenu.json';
import * as organization from './organization.json';
import * as adminusermanagement from './adminusermanagement.json';
import * as usermanagement from './usermanagement.json';
import * as lang from './lang.json';
import * as store from './store.json';
import * as color from './color.json';
import * as location from './location.json';
import * as bookingmanagement from './bookingmanagement.json'
import * as invoice from './invoice.json';
import * as  asset from './asset.json';
import * as asset_group from './asset_group.json';
import * as asset_type from './asset_type.json';
import * as firm from './firm.json';
import * as pattern from './pattern.json';
import * as permission_master from './permission_master.json';
import * as permission from './permission.json';
import * as role from './role.json';
import * as size from './size.json';
import * as stock_type from './stock_type.json';

const allGuj = {
  lang,
  sidebarmenu,
  organization,
  adminusermanagement,
  usermanagement,
  store,
  color,
  location,
  bookingmanagement,
  invoice,
  asset,
  asset_group,
  asset_type,
  firm,
  pattern,
  permission_master,
  permission,
  role,
  size,
  stock_type
};

export default allGuj;
