import {
  Config,
  apiDelete,
  apiGet,
  apiPatch,
  apiPost,
  apiPut
} from 'src/shared/utils/api-request';
// getOrgUserDataAPI
export const getInvoiceByTenantIdDataAPI = async (tenantId: string) => {
  const config = {} as Config;
  config.custom = {
    setLoading: () => {}
  };
  const res = await apiGet(`/v1/invoices/tenant/${tenantId}`);
  return res.data;
};

export const deleteTenantUserAPI = async (payload: any) => {
  const res = await apiDelete(`/tenant/user/${payload.email}`, payload);
  return res.data;
};

export const addTenantUserAPI = async (payload: any) => {
  const res = await apiPost(`/tenant/${payload.tenantId}/user`, {
    invoice_no: payload.invoice_no,
    transaction_no: payload.transaction_no,
    amount: payload.amount,
    transaction_date: payload.transaction_date,
      
  });
  return res.data;
};

export const updateTenantUserAPI = async (userData: any) => {
  const res = await apiPut(
    `/tenant/${userData.tenantId}/user/${userData.email}`,
    { roleId: userData.role }
  );
  return res.data;
};

export const updateTenantUserStatusAPI = async (userData: any) => {
  const res = await apiPatch(
    `/tenant/${userData.tenantId}/user/${userData.email}/enable`,
    { value: userData.value }
  );
  return res.data;
};

export const getUserAssetsDataAPI = async (payload) => {
  const config = {} as Config;
  config.custom = {
    setLoading: () => {}
  };
  const res = await apiGet(
    `/organization/${payload.orgId}/user/${payload.user}/assets`
  );
  return res.data;
};

export const deleteUserAssetAPI = async (payload: any) => {
  const res = await apiDelete(
    `organization/${payload.tenantId}/assets/${payload.assetId}/user/${payload.userId}`
  );
  return res.data;
};
