import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getReportDataAPI,
  findTransactionByTenantIdAPI,
  addReportDataAPI,
  updateReportDataAPI,
} from '../apis/reportManagementAPI';
import { ReportData } from '../models';
import { RootState } from 'src/store/reducer';


interface ReportManagementState {
  reportData: ReportData[];
  transactions: any[];
  loadingUserData: boolean;
  isOrgUserListUpdated: boolean;
  isError: boolean;
  errorMessage: string;
  userAssetsData: any;
  isExpenceAssetsListUpdated: boolean;

}

const initialState: ReportManagementState = {
  reportData: [],
  transactions: [],
  loadingUserData: false,
  isOrgUserListUpdated: false,
  isError: false,
  errorMessage: '',
  userAssetsData: [],
  isExpenceAssetsListUpdated: false
};
export const selectorReportManagementState = ({
  report
}: RootState) => ({
  reportData: report?.ReportData,
  transactions: report?.transactions,
  loadingExpenceData: report?.loadingExpenceData,
  setIsOrgReportDataListUpdated: report?.setIsOrgReportDataListUpdated,
  isError: report?.isError,
  errorMessage: report?.errorMessage,
  userAssetsData: report?.userAssetsData,
  isExpenceAssetsListUpdated: report?.isExpenceAssetsListUpdated,
});
export const findExpencesByTenantId = createAsyncThunk(
  'report/findExpencesByTenantId',
  async (tenantId: string) => {
    const response = await getReportDataAPI(tenantId);
    return response;
  }
);

export const findTransactionByTenantId = createAsyncThunk(
  'report/findTransactionByTenantId',
  async (payload: any) => {
    const response = await findTransactionByTenantIdAPI(payload);
    return response;
  }
);




export const updateReport = createAsyncThunk(
  'report/updateReport',
  async (reportData: ReportData, { rejectWithValue }) => {
    try {
      const response = await updateReportDataAPI(reportData);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setIsOrgReportDataListUpdated: (state: any, action) => {
      state.isOrgExpenceListUpdated = action.payload;
    },
    setIsError: (state: any, action) => {
      state.isError = action.payload;
      if (!action.payload) {
        state.errorMessage = '';
      }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(findExpencesByTenantId.pending, (state) => {
        state.loadingExpenceData = true;
        state.orgExpenceData = [];
      })
      .addCase(findExpencesByTenantId.fulfilled, (state: any, action) => {
        state.loadingExpenceData = false;
        if (action.payload) {
          const expences = action.payload;
          state.expences = expences;
        }
      })
      .addCase(findExpencesByTenantId.rejected, (state) => {
        state.loadingExpenceData = false;
      })
      .addCase(findTransactionByTenantId.pending, (state) => {
        state.loadingReportData = true;
      })
      .addCase(findTransactionByTenantId.fulfilled, (state: any, action) => {
        state.loadingReportData = false;
        if (action.payload) {
          const transactions = action.payload;
          state.transactions = transactions;
        } else {
          state.transactions = [];
        }

      })
      .addCase(findTransactionByTenantId.rejected, (state, action: any) => {
        state.loadingReportData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(updateReport.pending, (state) => {
        state.loadingReportData = true;
      })
      .addCase(updateReport.fulfilled, (state: any, action) => {
        state.loadingReportData = false;
        state.isOrgReportListUpdated = true;
      })
      .addCase(updateReport.rejected, (state, action: any) => {
        state.loadingReportData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
  }
});

export const { setIsOrgReportDataListUpdated, setIsError } =
  reportSlice.actions;
export default reportSlice.reducer;
