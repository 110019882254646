import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
 
  getExpenceDataAPI,
 
  addExpenceAPI,
  updateExpenceAPI
  
 
 
} from '../apis/expenceManagementAPI';
import { Expence } from '../models';
import { RootState } from 'src/store/reducer';

interface ExpenceManagementState {
  expences: Expence[];
  loadingUserData: boolean;
  isOrgUserListUpdated: boolean;
  isError: boolean;
  errorMessage: string;
  userAssetsData: any;
  isExpenceAssetsListUpdated: boolean;
}

const initialState: ExpenceManagementState = {
  expences: [],
  loadingUserData: false,
  isOrgUserListUpdated: false,
  isError: false,
  errorMessage: '',
  userAssetsData: [],
  isExpenceAssetsListUpdated: false
};
export const selectorExpenceManagementState = ({
  expence
}: RootState) => ({
  expences:expence?.expences,
  loadingExpenceData: expence?.loadingExpenceData,
  isOrgExpenceListUpdated: expence?.isOrgExpenceListUpdated,
  isError: expence?.isError,
  errorMessage: expence?.errorMessage,
  userAssetsData: expence?.userAssetsData,
  isExpenceAssetsListUpdated: expence?.isExpenceAssetsListUpdated,
});
export const findExpencesByTenantId = createAsyncThunk(
  'ExpenceManagementData/findExpencesByTenantId',
  async (tenantId: string) => {
    const response = await getExpenceDataAPI(tenantId);
    return response;
  }
);

// export const deleteTenantExpence = createAsyncThunk(
//   'ExpenceManagementData/deleteTenantExpence',
//   async (payload: any, { rejectWithValue }) => {
//     try {
//       const response = await deleteTenantExpenceAPI(payload);
//       return response;
//     } catch (error: any) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

export const addExpence = createAsyncThunk(
  'ExpenceManagementData/addExpence',
  async (expence: Expence, { rejectWithValue }) => {
    try {
      const response = await addExpenceAPI(expence);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateExpence = createAsyncThunk(
  'ExpenceManagementData/updateExpence',
  async (expence: Expence, { rejectWithValue }) => {
    try {
      const response = await updateExpenceAPI(expence);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// export const updateExpenceStatus = createAsyncThunk(
//   'ExpenceManagementData/updateAdminExpenceStatus',
//   async (userData: object, { rejectWithValue }) => {
//     try {
//       const response = await updateExpenceStatusAPI(userData);
//       return response;
//     } catch (error: any) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// const getExpenceInsert = (userList) => {
//   return [
//     ...userList.map((entry) => {
//       return {
//         id: entry.email,
//         email: entry.email,
//         name: entry.name,
//         isEnabled: entry.is_enabled,
//         role: {
//           id: entry.roles.id,
//           roleName: entry.roles.role_name
//         },
//         tenantId: entry.tenant_id
//       };
//     })
//   ];
// };

// const getAssetExpencesDTO = (masterList) => {
//   return [
//     ...masterList.map((entry) => {
//       if (entry.id) {
//         return entry;
//       }
//       return {
//         id: entry.user_id,
//         name: entry.asset_name,
//         description: entry.asset_description,
//         assetId: entry.asset_id,
//         userId: entry.user_id
//       };
//     })
//   ];
// };

// export const getExpenceAssetsData = createAsyncThunk(
//   'ExpenceManagementData/getExpenceAssetsData',
//   async (payload: any) => {
//     const response = await getExpenceAssetsDataAPI(payload);
//     return response;
//   }
// );

// export const deleteAssetOfExpence = createAsyncThunk(
//   'ExpenceManagementData/deleteTenantExpence',
//   async (payload: any, { rejectWithValue }) => {
//     try {
//       const response = await deleteTenantExpenceAPI(payload);
//       return response;
//     } catch (error: any) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// export const deleteExpenceAssets = createAsyncThunk(
//   'ExpenceManagementData/deleteExpenceAssets',
//   async (payload: any, { rejectWithValue }) => {
//     try {
//       const response = await deleteExpenceAssetAPI(payload);
//       return response;
//     } catch (error: any) {
//       return rejectWithValue(error.response.data);
//     }
//   
// );

export const expenceSlice = createSlice({
  name: 'expence',
  initialState,
  reducers: {
    setIsOrgExpenceListUpdated: (state: any, action) => {
      state.isOrgExpenceListUpdated = action.payload;
    },
    setIsError: (state: any, action) => {
      state.isError = action.payload;
      if (!action.payload) {
        state.errorMessage = '';
      }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(findExpencesByTenantId.pending, (state) => {
        state.loadingExpenceData = true;
        state.orgExpenceData = [];
      })
      .addCase(findExpencesByTenantId.fulfilled, (state: any, action) => {
        state.loadingExpenceData = false;
        if (action.payload) {
          const expences = action.payload;
          state.expences = expences;
        }
      })
      .addCase(findExpencesByTenantId.rejected, (state) => {
        state.loadingExpenceData = false;
      })
      .addCase(addExpence.pending, (state) => {
        state.loadingExpenceData = true;
      })
      .addCase(addExpence.fulfilled, (state: any, action) => {
        state.loadingExpenceData = false;
        state.isOrgExpenceListUpdated = true;
      })
      .addCase(addExpence.rejected, (state, action: any) => {
        state.loadingExpenceData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(updateExpence.pending, (state) => {
        state.loadingExpenceData = true;
      })
      .addCase(updateExpence.fulfilled, (state: any, action) => {
        state.loadingExpenceData = false;
        state.isOrgExpenceListUpdated = true;
      })
      .addCase(updateExpence.rejected, (state, action: any) => {
        state.loadingExpenceData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      // .addCase(deleteTenantExpence.pending, (state) => {
      //   state.loadingExpenceData = true;
      // })
      // .addCase(deleteTenantExpence.fulfilled, (state: any, action) => {
      //   state.loadingExpenceData = false;
      //   state.isOrgExpenceListUpdated = true;
      // })
      // .addCase(deleteTenantExpence.rejected, (state, action: any) => {
      //   state.loadingExpenceData = false;
      //   state.isError = true;
      //   state.errorMessage = action?.payload?.message
      //     ? action?.payload?.message
      //     : '';
    // })
      // .addCase(updateExpenceStatus.pending, (state) => {
      //   state.loadingExpenceData = true;
      // })
      // .addCase(updateExpenceStatus.fulfilled, (state: any, action) => {
      //   state.loadingExpenceData = false;
      //   state.isOrgExpenceListUpdated = true;
      // })
      // .addCase(updateExpenceStatus.rejected, (state, action: any) => {
      //   state.loadingExpenceData = false;
      //   state.isError = true;
      //   state.errorMessage = action?.payload?.message
      //     ? action?.payload?.message
      //     : '';
      // })
      // .addCase(getExpenceAssetsData.fulfilled, (state: any, action) => {
      //   state.loadingExpenceData = false;
      //   if (action.payload) {
      //     const userAssetsData = getAssetExpencesDTO(action.payload);
      //     state.userAssetsData = userAssetsData;
      //     state.isExpenceAssetsListUpdated = false;
      //   }
      // })
      // .addCase(deleteExpenceAssets.fulfilled, (state: any, action) => {
      //   state.loadingExpenceData = false;
      //   state.isExpenceAssetsListUpdated = true;
      // });
  }
});

export const { setIsOrgExpenceListUpdated, setIsError } =
expenceSlice.actions;
 export default expenceSlice.reducer;
