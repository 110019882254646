import DialogTitle from '@mui/material/DialogTitle';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  useTheme
} from '@mui/material';
// import { CustomDialogProps } from 'src/shared/components/modals/services/modalInterface';
// import { Dialog, DialogContent } from '@mui/material';
import { CustomDialogProps } from './services/modalInterface';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';

export const SimpleDialog = ({ ...props }: CustomDialogProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [fullWidth, setFullWidth] = React.useState(true);
  const {
    onClose,
    selectedValue,
    open,
    model_title,
    model_content,
    model_actions,
    customClass,
    maxWidth
  } = props;
  const [modalMaxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>(
    maxWidth ?? 'sm'
  );

  const handleClose = () => {
    onClose(selectedValue, 'backdropClick');
  };

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={modalMaxWidth}
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
      data-testid="modalcomponent"
      className={customClass}
    >
      <DialogTitle>{model_title}</DialogTitle>
      <DialogContent>{model_content}</DialogContent>
      <DialogActions>{model_actions}</DialogActions>
    </Dialog>
  );
};
