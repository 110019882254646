import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  deleteAssetTypeAPI,
  getAssetTypeAPI,
  updateAssetTypeAPI,
  addAssetTypeAPI,
} from '../apis/asset-typeManagementAPI';
import { AssetType } from '../models';
import { RootState } from 'src/store/reducer';

interface AssetTypeState {
  assetTypeData: AssetType[];

  loadingAssetTypeData: boolean;
  isOrgUserListUpdated: boolean;
  isError: boolean;
  errorMessage: string;
  userAssetsData: any;
  isUserAssetsListUpdated: boolean;
}

const initialState: AssetTypeState = {
  assetTypeData: [],
  loadingAssetTypeData: false,
  isOrgUserListUpdated: false,
  isError: false,
  errorMessage: '',
  userAssetsData: [],
  isUserAssetsListUpdated: false
};
export const selectorAssetTypeState = ({
  assettype
}: RootState) => ({
  assetTypeData: assettype?.assetTypeData,
  loadingAssetTypeData: assettype?.loadingAssetTypeData,
  isOrgUserListUpdated: assettype?.isOrgUserListUpdated,
  isError:assettype?.isError,
  errorMessage: assettype?.errorMessage,
  userAssetsData: assettype?.userAssetsData,
  isUserAssetsListUpdated: assettype?.isUserAssetsListUpdated,
});
export const getAssetTypeData = createAsyncThunk(
  'AssetType/getAssetTypeData',
  async (tenantId: string) => {
    const response = await getAssetTypeAPI(tenantId);
    return response;
  }
);

export const deleteAssetType = createAsyncThunk(
  'AssetType/deleteAssetType',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await deleteAssetTypeAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addAssetType = createAsyncThunk(
  'AssetType/addAssetType',
  async (UserPayload: any, { rejectWithValue }) => {
    try {
      const response = await addAssetTypeAPI(UserPayload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAssetType = createAsyncThunk(
  'AssetType/updateAssetType',
  async (userData: object, { rejectWithValue }) => {
    try {
      const response = await updateAssetTypeAPI(userData);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const assettypeSlice = createSlice({
  name: 'assetTypeData',
  initialState,
  reducers: {
    setIsAdminListUpdated: (state: any, action) => {
      state.isOrgUserListUpdated = action.payload;
    },
    setIsError: (state: any, action) => {
      state.isError = action.payload;
      if (!action.payload) {
        state.errorMessage = '';
      }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAssetTypeData.pending, (state) => {
        state.loadingAssetTypeData = true;
        state.assetTypeData = [];
      })
      .addCase(getAssetTypeData.fulfilled, (state: any, action) => {
        state.loadingAssetTypeData = false;
        if (action.payload) {
          const assetTypeData = action.payload;
          state.assetTypeData = assetTypeData;
        }
      })
      .addCase(getAssetTypeData.rejected, (state) => {
        state.loadingAssetTypeData = false;
      })
      .addCase(addAssetType.pending, (state) => {
        state.loadingAssetTypeData = true;
      })
      .addCase(addAssetType.fulfilled, (state: any, action) => {
        state.loadingAssetTypeData = false;
        state.isOrgUserListUpdated = true;
      })
      .addCase(addAssetType.rejected, (state, action: any) => {
        state.loadingAssetTypeData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(updateAssetType.pending, (state) => {
        state.loadingAssetTypeData = true;
      })
      .addCase(updateAssetType.fulfilled, (state: any, action) => {
        state.loadingAssetTypeData = false;
        state.isOrgUserListUpdated = true;
      })
      .addCase(updateAssetType.rejected, (state, action: any) => {
        state.loadingAssetTypeData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(deleteAssetType.pending, (state) => {
        state.loadingAssetTypeData = true;
      })
      .addCase(deleteAssetType.fulfilled, (state: any, action) => {
        state.loadingAssetTypeData = false;
        state.isOrgUserListUpdated = true;
      })
      .addCase(deleteAssetType.rejected, (state, action: any) => {
        state.loadingAssetTypeData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
  }
});

export const { setIsAdminListUpdated, setIsError } =
  assettypeSlice.actions;
export default assettypeSlice.reducer;
