import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  deleteTenantUserAPI,
  getAssetGroupByAssetIdAPI,
  updateAssetGroupAPI,
  addAssetGroupAPI,
  updateTenantUserStatusAPI,
} from '../apis/asset-groupManagementAPI';
import { AssetGroup } from '../models';
import { RootState } from 'src/store/reducer';

interface UserManagementState {
  assetGroupData: AssetGroup[];


  loadingAssetGroupData: boolean;
  isAssetGroupsUpdated: boolean;
  isError: boolean;
  errorMessage: string;
  userAssetsData: any;
  isUserAssetsListUpdated: boolean;
}

const initialState: UserManagementState = {
  assetGroupData: [],
  loadingAssetGroupData: false,
  isAssetGroupsUpdated: false,
  isError: false,
  errorMessage: '',
  userAssetsData: [],
  isUserAssetsListUpdated: false
};
export const selectorAssetGroupState = ({
  assetGroupData
}: RootState) => ({
  assetGroupData:assetGroupData?.assetGroupData,
   loadingAssetGroupData: assetGroupData?.loadingAssetGroupData,
   isAssetGroupsUpdated: assetGroupData?.isAssetGroupsUpdated,
   isError:assetGroupData?.isError,
   errorMessage:assetGroupData?.errorMessage,
   userAssetsData: assetGroupData?.userAssetsData,
   isUserAssetsListUpdated:assetGroupData?.isUserAssetsListUpdated,
});
export const getAssetGroupByTenantId = createAsyncThunk(
  'assetGroupData/getAssetGroupByAssetId',
  async (orgId: string) => {
    const response = await getAssetGroupByAssetIdAPI(orgId);
    return response;
  }
);

//Will update for delete User
export const deleteTenantUser = createAsyncThunk(
  'assetGroupData/deleteTenantUser',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await deleteTenantUserAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addAssetGroup = createAsyncThunk(
  'assetGroupData/addTenantUser',
  async (UserPayload: any, { rejectWithValue }) => {
    try {
      const response = await addAssetGroupAPI(UserPayload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAssetGroup = createAsyncThunk(
  'assetGroupData/updateAssetGroup',
  async (userData: object, { rejectWithValue }) => {
    try {
      const response = await updateAssetGroupAPI(userData);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

//TODO will replace it for update status
export const updateTenantUserStatus = createAsyncThunk(
  'UserManassetGroupDataagementData/updateAdminUserStatus',
  async (userData: object, { rejectWithValue }) => {
    try {
      const response = await updateTenantUserStatusAPI(userData);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const assetgroupSlice = createSlice({
  name: 'assetGroupData',
  initialState,
  reducers: {
    setIsAdminListUpdated: (state: any, action) => {
      state.isAssetGroupsUpdated = action.payload;
    },
    setIsError: (state: any, action) => {
      state.isError = action.payload;
      if (!action.payload) {
        state.errorMessage = '';
      }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAssetGroupByTenantId.pending, (state) => {
        state.loadingAssetGroupData = true;
        state.assetGroupData = [];
      })
      .addCase(getAssetGroupByTenantId.fulfilled, (state: any, action) => {
        state.loadingAssetGroupData = false;
        if (action.payload) {
          const assetGroupData = action.payload;
          state.assetGroupData = assetGroupData;
        }
      })
      .addCase(getAssetGroupByTenantId.rejected, (state) => {
        state.loadingAssetGroupData = false;
      })
      .addCase(addAssetGroup.pending, (state) => {
        state.loadingAssetGroupData = true;
      })
      .addCase(addAssetGroup.fulfilled, (state: any, action) => {
        state.loadingAssetGroupData = false;
        state.isAssetGroupsUpdated = true;
      })
      .addCase(addAssetGroup.rejected, (state, action: any) => {
        state.loadingAssetGroupData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(updateAssetGroup.pending, (state) => {
        state.loadingAssetGroupData = true;
      })
      .addCase(updateAssetGroup.fulfilled, (state: any, action) => {
        state.loadingAssetGroupData = false;
        state.isAssetGroupsUpdated = true;
      })
      .addCase(updateAssetGroup.rejected, (state, action: any) => {
        state.loadingAssetGroupData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(deleteTenantUser.pending, (state) => {
        state.loadingAssetGroupData = true;
      })
      .addCase(deleteTenantUser.fulfilled, (state: any, action) => {
        state.loadingAssetGroupData = false;
        state.isAssetGroupsUpdated = true;
      })
      .addCase(deleteTenantUser.rejected, (state, action: any) => {
        state.loadingAssetGroupData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(updateTenantUserStatus.pending, (state) => {
        state.loadingAssetGroupData = true;
      })
      .addCase(updateTenantUserStatus.fulfilled, (state: any, action) => {
        state.loadingAssetGroupData = false;
        state.isAssetGroupsUpdated = true;
      })
      .addCase(updateTenantUserStatus.rejected, (state, action: any) => {
        state.loadingAssetGroupData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
  }
});

export const { setIsAdminListUpdated, setIsError } =
assetgroupSlice.actions;
export default assetgroupSlice.reducer;
