import {
  Config,
  apiDelete,
  apiGet,
  apiPatch,
  apiPost,
  apiPut
} from 'src/shared/utils/api-request';

export const getAssetGroupByAssetIdAPI = async (orgId: string) => {
  const config = {} as Config;
  config.custom = {
    setLoading: () => {}
  };
  const res = await apiGet(`/v1/asset-groups/tenant/${orgId}`);
  return res.data;
};

export const deleteTenantUserAPI = async (payload: any) => {
  const res = await apiDelete(`/v1/asset-groups/${payload.id}/tenant/${payload.tenantId}`, payload);
  return res.data;
};

export const addAssetGroupAPI = async (payload: any) => {
  const res = await apiPost(`v1/asset-groups`, payload);
  return res.data;
};

export const updateAssetGroupAPI = async (payload: any) => {
  const res = await apiPut(
    `/v1/asset-groups`,
    payload
  );
  return res.data;
};

export const updateTenantUserStatusAPI = async (userData: any) => {
  const res = await apiPatch(
    `/tenant/${userData.tenantId}/user/${userData.email}/enable`,
    { value: userData.value }
  );
  return res.data;
};
