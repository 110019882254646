import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SidebarMenus } from 'src/shared/components/index';
import { ROLES, USER_TYPE } from 'src/shared/constants/constants';
import * as ROUTES from 'src/shared/constants/routes';
import { RootState } from 'src/store/reducer';
import { selectUserState } from 'src/auth/login/services/userSlice';
const SidebarMenu = () => {
  const { loginUserRole, selectedOrganization, loginUserType } =
    useSelector(selectUserState);
  const [menuList, setMenuList] = useState([]);
  const [assetsList, setAssetsList] = useState([]);


  
  const organization = {
    pathname: ROUTES.ADMIN_LOGIN,
    subpathname: ROUTES.ORGANIZATION,
    icon: <TableChartTwoToneIcon />,
    submenuname: 'organization'
  };

  const adminManagement = {
    pathname: ROUTES.ADMIN_LOGIN,
    subpathname: ROUTES.ADMIN_MANAGEMENT,
    icon: <TableChartTwoToneIcon />,
    submenuname: 'admin_management'
  };

  const userManagement = {
    pathname: ROUTES.USER_MANAGEMENT,
    subpathname: '',
    icon: <TableChartTwoToneIcon />,
    submenuname: 'user_management'
  };

  const BookingManagement = {
    pathname: ROUTES.BOOKING_MANAGEMENT,
    subpathname: '',
    icon: <TableChartTwoToneIcon />,
    submenuname: 'booking_management'
  };

  const BookingView = {
    pathname: ROUTES.BOOKING_VIEW,
    subpathname: '',
    icon: <TableChartTwoToneIcon />,
    submenuname: 'booking_view'
  };


  const Invoice = {
    pathname: ROUTES.INVOICE_MANAGEMENT,
    subpathname: '',
    icon: <TableChartTwoToneIcon />,
    submenuname: 'invoice_management'
  };

  // const Store = {
  //   pathname: ROUTES.STORE_MANAGEMENT,
  //   subpathname: '',
  //   icon: <TableChartTwoToneIcon />,
  //   submenuname: 'store_management'
  // };

  const Expence = {
    pathname: ROUTES.EXPENCE_MANAGEMENT,
    subpathname: '',
    icon: <TableChartTwoToneIcon />,
    submenuname: 'expence_management'
  };

  const Asset = {
    pathname: ROUTES.ASSET_MANAGEMENT,
    subpathname: '',
    icon: <TableChartTwoToneIcon />,
    submenuname: 'asset_management'
  };

  const AssetSL = {
    pathname: ROUTES.SOLD_ASSET_MANAGEMENT,
    subpathname: '',
    icon: <TableChartTwoToneIcon />,
    submenuname: 'asset_car_management'
  };

  
  const AssetDetails = {
    pathname: ROUTES.ASSET_DETAILS,
    subpathname: '',
    icon: <TableChartTwoToneIcon />,
    submenuname: 'asset_details'
  };


  const Assetgroup = {
    pathname: ROUTES.ASSET_GROUP_MANAGEMENT,
    subpathname: '',
    icon: <TableChartTwoToneIcon />,
    submenuname: 'asset-group_management'
  };

  const Assettype = {
    pathname: ROUTES.ASSET_TYPE_MANAGEMENT,
    subpathname: '',
    icon: <TableChartTwoToneIcon />,
    submenuname: 'asset-type_management'
  };

  // const Color = {
  //   pathname: ROUTES.COLOR_MANAGEMENT,
  //   subpathname: '',
  //   icon: <TableChartTwoToneIcon />,
  //   submenuname: 'color_management'
  // };

  // const Firm = {
  //   pathname: ROUTES.FIRM_MANAGEMENT,
  //   subpathname: '',
  //   icon: <TableChartTwoToneIcon />,
  //   submenuname: 'firm_management'
  // };

  // const Location = {
  //   pathname: ROUTES.LOCATION_MANAGEMENT,
  //   subpathname: '',
  //   icon: <TableChartTwoToneIcon />,
  //   submenuname: 'location_management'
  // };

  // const Pattern = {
  //   pathname: ROUTES.PATTERN_MANAGEMENT,
  //   subpathname: '',
  //   icon: <TableChartTwoToneIcon />,
  //   submenuname: 'pattern_management'
  // };

  // const Permission_master = {
  //   pathname: ROUTES.PERMISSION_MASTER_MANAGEMENT,
  //   subpathname: '',
  //   icon: <TableChartTwoToneIcon />,
  //   submenuname: 'permission-master_management'
  // };

  // const Permission = {
  //   pathname: ROUTES.PERMISSION_MANAGEMENT,
  //   subpathname: '',
  //   icon: <TableChartTwoToneIcon />,
  //   submenuname: 'permission_management'
  // };

  // const Role = {
  //   pathname: ROUTES.ROLE_MANAGEMENT,
  //   subpathname: '',
  //   icon: <TableChartTwoToneIcon />,
  //   submenuname: 'role_management'
  // };

  const Report = {
    pathname: ROUTES.REPORT_MANAGEMENT,
    subpathname: '',
    icon: <TableChartTwoToneIcon />,
    submenuname: 'Report_Management'
  };

  // const Size = {
  //   pathname: ROUTES.SIZE_MANAGEMENT,
  //   subpathname: '',
  //   icon: <TableChartTwoToneIcon />,
  //   submenuname: 'size_management'
  // };

  // const Stock_type = {
  //   pathname: ROUTES.STOCK_TYPE_MANAGEMENT,
  //   subpathname: '',
  //   icon: <TableChartTwoToneIcon />,
  //   submenuname: 'stock-type_management'
  // };


  useEffect(() => {
    const list = [];
    setMenuList([]);
    setAssetsList([]);

    const pathName = window.location.pathname;
    if (
      pathName === `${ROUTES.ADMIN_LOGIN}${ROUTES.ORGANIZATION}` ||
      pathName === `${ROUTES.ADMIN_LOGIN}${ROUTES.ADMIN_MANAGEMENT}` ||
      pathName === `${ROUTES.TENANT_LOGIN}${ROUTES.ORGANIZATION}`
    ) {
      list.push(organization);
      if (loginUserRole === ROLES.SUPER_ADMIN) {
        list.push(adminManagement);
      }
    }
    if (pathName === ROUTES.USER_MANAGEMENT || pathName === ROUTES.BOOKING_MANAGEMENT
      || pathName === ROUTES.INVOICE_MANAGEMENT  ||pathName === ROUTES.STORE_MANAGEMENT
      || pathName === ROUTES.EXPENCE_MANAGEMENT || pathName === ROUTES.ASSET_MANAGEMENT
      || pathName === ROUTES.ASSET_GROUP_MANAGEMENT || pathName === ROUTES.ASSET_TYPE_MANAGEMENT
      || 
       pathName === ROUTES.COLOR_MANAGEMENT   ||
       pathName === ROUTES.FIRM_MANAGEMENT  || pathName === ROUTES.LOCATION_MANAGEMENT || 
       pathName === ROUTES.PATTERN_MANAGEMENT 
      || pathName === ROUTES.PERMISSION_MASTER_MANAGEMENT  
       || pathName === ROUTES.PERMISSION_MANAGEMENT   
       ||  pathName === ROUTES.REPORT_MANAGEMENT
      || pathName === ROUTES.ROLE_MANAGEMENT  || pathName === ROUTES.SIZE_MANAGEMENT 
      || pathName === ROUTES.STOCK_TYPE_MANAGEMENT ||
       pathName === ROUTES.BOOKING_VIEW ||   pathName === ROUTES.ASSET_DETAILS || pathName === ROUTES.SOLD_ASSET_MANAGEMENT
    ) {
      if (
        loginUserRole === ROLES.TENANT_ADMIN ||
        loginUserRole === ROLES.SUPER_ADMIN ||
        loginUserRole === ROLES.ADMIN_OPERATOR
      ) {
        list.push(userManagement);
        if (!selectedOrganization.isAssetSold) {
          list.push(BookingManagement);
        }
        list.push(Report);
        if (!selectedOrganization.isAssetSold) {
          list.push(Invoice);
        }
        // list.push(Store);
        list.push(Expence);
        
        if (!selectedOrganization.isAssetSold) {
          list.push(Asset);
          list.push(Assetgroup);
          list.push(Assettype);
        } else {
          list.push(AssetSL);
        }

        
        // list.push(Color);
        // list.push(Firm);
        // list.push(Location);
        // list.push(Pattern);
        // list.push(Permission_master);
        // list.push(Permission);
        // list.push(Role);
        // list.push(Size);
        // list.push(Stock_type);
      } else if (loginUserRole === ROLES.TENANT_OPERATOR) {
        list.push(userManagement);
        if (!selectedOrganization.isAssetSold) {
          list.push(BookingManagement);
        }
      } else if (loginUserRole === ROLES.END_USER) {
        if (!selectedOrganization.isAssetSold) {
          list.push(BookingManagement);
        }
      }
      setMenuList(list);
    } else {
      setMenuList(list);
    }
  }, [window.location.pathname, selectedOrganization]);
  return (
    <>
      <SidebarMenus menuname={''} option={menuList} />
      <SidebarMenus menuname={'assets'} option={assetsList} />
    </>
  );
};

export default SidebarMenu;
